export type Medida = {
  tipo: string;
  totalAmount: number;
  totalProductivity: number;
  totalAcumulated: number;
  totalROB: number;
  tipoOperacion: string;
};

export class Sumatoria {
  private medidas: Map<string, Map<string, Medida>>; // Primer Map: tipoOperacion, Segundo Map: tipo

  constructor() {
    this.medidas = new Map<string, Map<string, Medida>>();
  }

  reiniciar(): void {
    this.medidas.clear();
  }

  agregarMedida(medida: Medida): void {
    // Verificamos si ya existe el tipoOperacion en el primer Map
    if (!this.medidas.has(medida.tipoOperacion)) {
      this.medidas.set(medida.tipoOperacion, new Map<string, Medida>());
    }

    // Ahora verificamos si existe el tipo dentro de esa operación
    const medidasPorOperacion = this.medidas.get(medida.tipoOperacion);
    if (medidasPorOperacion) {
      if (medidasPorOperacion.has(medida.tipo)) {
        // Si ya existe el tipo, sumamos los valores
        const medidaActual = medidasPorOperacion.get(medida.tipo);
        if (medidaActual) {
          medidaActual.totalAmount += medida.totalAmount;
          medidaActual.totalProductivity += medida.totalProductivity;
          medidaActual.totalAcumulated += medida.totalAcumulated;
          medidaActual.totalROB += medida.totalROB;
        }
      } else {
        // Si no existe, la agregamos
        medidasPorOperacion.set(medida.tipo, { ...medida });
      }
    }
  }

  obtenerMedidas(): Map<string, Map<string, Medida>> {
    return this.medidas;
  }
}
