import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as S3 from 'aws-sdk/clients/s3';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
/* Services */
import { MainService } from '../services/main.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  documento: any;

  public selectedFile: any;
  bucket: S3 = new S3({
    accessKeyId: 'AKIAS2VS4J2QIJNW3TB6',
    secretAccessKey: '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',
    region: 'us-east-1',
  });

  documentos: any = {};
  dataBarcos: any = [];

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: 'edit', title: '<i class="nb-compose"></i> ' }],
    },
    columns: {
      nv: {
        title: 'nv',
      },
      vessel: {
        title: 'Barco',
      },
      port: {
        title: 'Puerto',
      },
      eta: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
      empresa: {
        title: 'Empresa',
        valuePrepareFunction: (data) => {
          return data.razonSocial;
        },
      },
    },
  };

  datos: any;
  enviado: boolean = false;

  public usuario: any;
  public data: any;
  public source: LocalDataSource;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.onCargar();
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
    }
  }

  onEditar(dialog, event) {
    this.documentos = {};
    this.documentos = event.data;
    this.documentos._id = event.data._id;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  makeid() {
    return this.utilsService.generateRandomId();
  }

  abrirFile(num) {
    if (num == 1) {
      const element: HTMLElement = document.getElementById('archivo1');
      element.click();
    } else if (num == 2) {
      const element: HTMLElement = document.getElementById('archivo2');
      element.click();
    } else if (num == 3) {
      const element: HTMLElement = document.getElementById('archivo3');
      element.click();
    } else {
      const element: HTMLElement = document.getElementById('archivo4');
      element.click();
    }
  }
  /** CRUD **/

  public onFileSelect(event, num) {
    this.selectedFile = event.target.files[0];
    let self = this;
    if (this.selectedFile !== undefined) {
      var file = this.selectedFile;
      let nombre = this.makeid();
      var params = {
        Bucket: 'navescol-recursos',
        ACL: 'public-read',
        Key: 'documentos/' + nombre + '.pdf',
        ContentType: file.type,
        Body: file,
      };
      var options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
      };

      var uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.error(err);
        }

        self.documento = data.Location.toString();
        if (num == 1) {
          self.documentos.archivoBillOfLanding = self.documento;
        } else if (num == 2) {
          self.documentos.archivoStowagePlan = self.documento;
        } else if (num == 3) {
          self.documentos.archivoDischarge = self.documento;
        } else {
          self.documentos.archivoIsps = self.documento;
        }

        return true;
      });
      uploader.on('httpUploadProgress', function (event) {
        console.log('UPDATE');
        console.log(event);
        console.log((event.loaded * 100) / event.total);
      });
    }
  }

  onActualizar() {
    let barco = {
      archivoBillOfLanding: this.documentos.archivoBillOfLanding,
      archivoStowagePlan: this.documentos.archivoStowagePlan,
      archivoDischarge: this.documentos.archivoDischarge,
      archivoIsps: this.documentos.archivoIsps,
    };
    let a = 'a';
    this.mainService.put('api/barco/' + this.documentos._id, barco).subscribe((res) => {
      console.log(res, 'actualizar');
      if (res) {
        Swal.fire('Éxito', 'Se agregó los documentos exitosamente', 'success');
      } else {
        Swal.fire('Error', 'No se pudieron agregar los documentos, verifique la información', 'warning');
      }
    });
  }

  onCargar() {
    let x = this;
    this.data = [];
    console.log('Cargando');
    this.mainService.get('api/barco').subscribe((res) => {
      console.log(res, 'soy lo que sale');
      let datos = res;

      datos.forEach((element) => {
        element.eta = moment(element.eta).format('YYYY-MM-DD');
      });
      this.data = _.orderBy(datos, ['eta'], ['desc']);
      this.data = new LocalDataSource(this.data);
      console.log('Data final ', this.data);
    });
  }
}
