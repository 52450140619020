import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  template: `
    <i class="nb-bar-chart" (click)="onLiquidacion()" style="cursor: pointer; font-size: 35px;"></i>
    <i class="nb-trash" (click)="onEliminarLiquidacion()" style="cursor: pointer; font-size: 35px;"></i>
  `,
  styles: []
})
export class CustomButtonComponent {
  @Input() rowData: any; // Recibe la data de la fila actual
  @Output() action = new EventEmitter<{ action: string, data: any }>();

  onLiquidacion() {
    this.action.emit({ action: 'liquidacion', data: this.rowData });
  }

  onEliminarLiquidacion() {
    this.action.emit({ action: 'eliminar-liquidacion', data: this.rowData });
  }
}
