import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbMenuItem } from '@nebular/theme';
export const Menu: NbMenuItem[] = [
  { title: 'Dashboard', icon: 'nb-home', home: true, link: 'dashboard' },
  {
    title: 'Parámetros',
    children: [
      {
        title: 'Buques',
        icon: 'nb-list',
        link: 'buques',
      },
      {
        title: 'Parámetros Sistema',
        link: 'parametros',
        icon: 'nb-list',
      },
      {
        title: 'Usuarios',
        icon: 'nb-list',
        link: 'usuarios',
      },
      {
        title: 'Empresas',
        link: 'empresa-sql',
        icon: 'nb-list',
      },
      {
        title: 'Áreas Empresa',
        link: 'areas-empresa',
        icon: 'nb-list',
      },
      {
        title: 'Contactos Empresa',
        link: 'contactos',
        icon: 'nb-list',
      },
      {
        title: 'Productos',
        icon: 'nb-list',
        link: 'producto',
      },
      {
        title: 'Medidas',
        icon: 'nb-list',
        link: 'medida',
      },
      {
        title: 'Business Line',
        icon: 'nb-list',
        link: 'bussines-line',
      },
      {
        title: 'Commodity',
        link: 'commodity',
        icon: 'nb-list',
      },
      {
        title: 'Kim',
        link: 'kim',
        icon: 'nb-list',
      },
      { title: 'Roles', icon: 'nb-list', link: 'roles' },
    ],
  },
  {
    title: 'Operaciones',
    children: [
      {
        title: 'Terminales',
        link: 'terminal',
        icon: 'nb-list',
      },
      {
        title: 'Puertos',
        link: 'puerto',
        icon: '	nb-list',
      },
      {
        title: 'Análisis Operativo',
        icon: 'nb-list',
        link: 'analisis-operativo',
      },
      {
        title: 'Viajes',
        icon: 'nb-list',
        link: 'viajes',
      },
      {
        title: 'Producto Viajes',
        icon: 'nb-list',
        link: 'producto-viaje',
      },
      {
        title: 'Formato SOF',
        link: 'formato-sof',
        icon: 'nb-list',
      },
      {
        title: 'Categorías SOF',
        icon: 'nb-list',
        link: 'categorias',
      },
      {
        title: 'Remarks SOF',
        icon: 'nb-list',
        link: 'tipo-comentarios',
      },
      { title: 'Tipo Operación', link: 'operacion', icon: 'nb-list' },
      { title: 'Husbandry', icon: 'nb-list', link: 'husbandry' },
      { title: 'Captain Surveys', icon: 'nb-list', link: 'captian-survies' },
    ],
  },
  {
    title: 'Financiero',
    children: [
      { title: 'Proformas', icon: 'nb-list', link: 'proformas' },
      { title: 'Liquidaciones', icon: 'nb-list', link: 'liquidaciones' },
      { title: 'Tarifas', icon: 'nb-list', link: 'tarifas' },
    ],
  },
  {
    title: 'Gestión',
    children: [
      { title: 'PQRs', link: 'pqrs', icon: 'nb-list' },
      { title: 'Noticias', icon: 'nb-list', link: 'noticias' },
      { title: 'Notificaciones', icon: 'nb-list', link: 'notificaciones' },
    ],
  },
];

export const MenuOperario: NbMenuItem[] = [
  { title: 'Dashboard', icon: 'nb-home', home: true, link: 'dashboard' },
  { title: 'Viajes', icon: 'nb-list', link: 'barcos' },
  { title: 'Noticias', icon: 'nb-paper-plane', link: 'noticias' },
  { title: 'Notificaciones', icon: 'nb-notifications', link: 'notificaciones' },
];

export const MenuOperadorCentral: NbMenuItem[] = [
  { title: 'Dashboard', icon: 'nb-home', home: true, link: 'dashboard' },
  { title: 'Empresas', link: 'empresa-sql', icon: 'nb-tables' },
  { title: 'Viajes', icon: 'nb-list', link: 'barcos' },
  { title: 'Productos', icon: 'nb-list', link: 'producto' },
  { title: 'Husbandry', icon: 'nb-email', link: 'husbandry' },
  { title: 'Buques', icon: 'nb-menu', link: 'buques' },
  { title: 'Noticias', icon: 'nb-paper-plane', link: 'noticias' },
  { title: 'Notificaciones', icon: 'nb-notifications', link: 'notificaciones' },
  { title: 'Servicios', link: 'servicios', icon: 'nb-lightbulb' },
  { title: 'Commodity', link: 'commodity', icon: 'nb-list' },
  { title: 'Terminal', link: 'terminal', icon: 'nb-location' },
  { title: 'Puerto', link: 'puerto', icon: '	nb-grid-a-outline' },
  { title: 'Operacion', link: 'operacion', icon: 'nb-search' },
];

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MenuModule {}
