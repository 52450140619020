import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../../../../services/main.service';
import { Router } from '@angular/router';
import { ReportesService } from '../../../../reportes.service';
import { Sumatoria, Medida } from './sumatoria';
import * as moment from 'moment';

@Component({
  selector: 'vista-previa-reporte-productividad',
  templateUrl: './vista-previa-reporte-productividad.component.html',
  styleUrls: ['./vista-previa-reporte-productividad.component.scss'],
})
export class VistaPreviaReporteProductividadComponent implements OnInit {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  public companyName: string;
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Arreglo con los parrafos del remark*/
  public parrafosRemarks: string[] = [];
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = false;
  /** Productos del viaje */
  public productosViaje: any;
  /** Imágen del buque */
  public imagenBuque: string;
  public iconBuque: string;
  public sumatoria: Sumatoria;
  public tipoOperacion: string = '';
  /** Productividad total */
  public productividadTotal: number[] = [];

  /** Lista de correos con copia y copia oculta */
  public correosCopia: string[];
  public correosCopiaOculta: string[];

  public showIncludeProductivityDetails: boolean;
  public showIncludeStoppedHours: boolean;
  public showSOFActivities: boolean;
  public stoppedHoursReport;
  private operaciones: any = {};
  public showHatch = false;

  public calculatedProductivityDetials = [];

  asunto: string;
  attachments: any[];

  sofConfirmadas: any[];
  sofEstimadas: any[];

  public productsForView: any[] = [];
  public productsByHolds: { [key: string]: { hold: string; products: any[] } } = {};
  public maxHolds = 20;

  constructor(private mainService: MainService, public router: Router, private reportesService: ReportesService) {
    this.sumatoria = new Sumatoria();

    for (let i = 1; i <= this.maxHolds; i++) {
      this.productsByHolds[`H${i}`] = {
        hold: `H${i}`,
        products: [],
      };
    }
    this.productsForView = Object.values(this.productsByHolds).reverse();
  }

  ngOnInit() {
    this.obtenerViaje();
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de prospectos e inicializa el viaje en el formato requerido para
   * el componente de reporte de prospectos
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
      try {
        this.viaje = res;

        this.viaje.commenceOpsVisual =
          this.viaje &&
          this.viaje.portLog &&
          this.viaje.portLog.commenceOPS &&
          this.viaje.portLog.commenceOPS.fecha &&
          this.viaje.portLog.commenceOPS.status == 'CONFIRMED'
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.portLog.commenceOPS.fecha)
            : 'Not registered';
        this.viaje.etcVisual =
          this.viaje && this.viaje.etc
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
            : 'Not registered';
        this.viaje.etdVisual =
          this.viaje && this.viaje.etd
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
            : 'Not registered';

        await this.onCargarProductos();
        this.obtenerProductosViaje();

        this.parrafosRemarks = this.viaje.remark ? this.viaje.remark.split('\n') : [];
        this.cargando = false;
      } catch (err) {
        console.error(err);
      }
    });
  }

  public includeProductivityDetails() {
    this.showIncludeProductivityDetails = !this.showIncludeProductivityDetails;
  }

  public includeStoppedHours() {
    this.showIncludeStoppedHours = !this.showIncludeStoppedHours;
  }

  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  public showVessel = false;
  public includeVesselGraphic() {
    this.showVessel = !this.showVessel;
  }

  /**
   * Revisa si es de tipo objeto
   * @param posible El posible objeto
   * @returns {boolean}
   */
  isObject(posible) {
    return typeof posible === 'object';
  }

  /*  public productsByHolds = {
    H1: {
      hold: 'H1',
      products: [],
    },
    H2: {
      hold: 'H2',
      products: [],
    },
    H3: {
      hold: 'H3',
      products: [],
    },
    H4: {
      hold: 'H4',
      products: [],
    },
    H5: {
      hold: 'H5',
      products: [],
    },
    H6: {
      hold: 'H6',
      products: [],
    },
  }; */
  /* public productsForView = Object.values(this.productsByHolds).reverse(); */

  public products = [];
  async onCargarProductos() {
    const products = await this.mainService.get(`api/producto/commodity/${this.viaje.commodity.id}`).toPromise();
    this.products = products;
  }

  hasProduct(hold: any): boolean {
    return hold.products.some((product: any) => product.PRV_ABV);
  }

  /**
   * Obtiene los productos del viaje
   */
  async obtenerProductosViaje(): Promise<void> {
    try {
      const res = await this.mainService.get(`api/producto_completo/recalada/${this.viaje.recalada._id}`).toPromise();
      this.productosViaje = res;
      this.operaciones = {}; // Inicializar el objeto

      for (const product of this.productosViaje) {
        if (!this.productsByHolds[product.HOLD_NUMBER]) continue;

        await this.productoAFromatoVisual(product); // Esperar a que se complete el procesamiento

        this.productsByHolds[product.HOLD_NUMBER].products.push(product);

        const tipo = product.nombreTipoOperacion || 'Desconocido';
        if (!this.operaciones[tipo]) {
          this.operaciones[tipo] = [];
        }
        this.operaciones[tipo].push(product);
      }
    } catch (error) {
      console.error('Error al obtener productos del viaje:', error);
    }
  }

  async productoAFromatoVisual(producto: any): Promise<void> {
    producto.createdAt = moment(producto.createdAt).format('DD-MM-YYYY HH:mm');

    // Calcular productividad parcial
    const productividadParcial = moment(producto.PRV__TIME_TO).diff(moment(producto.PRV__TIME_FROM), 'hours');

    // Obtener nombre del producto
    const productoRes = await this.mainService.get(`api/producto?PRD_ID=${producto.PRD_ID}`).toPromise();
    const productoAsociado = productoRes.find((element: any) => element.PRD_ID === producto.PRD_ID);
    producto.nombreProducto = (productoAsociado && productoAsociado.PRD_NOMBRE) || 'Producto no encontrado';

    // Obtener nombre de medida
    const medidaRes = await this.mainService.get(`api/medida?MED_ID=${producto.MED_ID}`).toPromise();
    const medida = medidaRes.find((element: any) => element.MED_ID === producto.MED_ID);
    producto.nombreMedida = (medida && medida.MED_NOMBRE) || 'Medida no encontrada';

    // Obtener tipo de operación
    const operacionRes = await this.mainService.get(`api/operacion?TOP_ID=${producto.TOP_ID}`).toPromise();
    producto.nombreTipoOperacion = (operacionRes[0] && operacionRes[0].TOP_NOMBRE) || 'Tipo de operación no encontrada';

    this.sumatoria.agregarMedida({
      tipo: producto.nombreMedida,
      totalAmount: producto.PRV_CANTIDAD,
      totalProductivity: producto.PRV_PROD_12H,
      totalAcumulated: producto.PRV_ACUMULADO,
      totalROB: producto.PRV_ROB,
      tipoOperacion: producto.nombreTipoOperacion,
    });

    // Formatear datos visuales
    producto.acumuladoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_ACUMULADO);
    producto.productividadVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_PROD_12H);
    producto.planificadoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_CANTIDAD);
    producto.robVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_ROB);

    producto.holdInfo = `H${producto.HOLD_NUMBER}`;
    this.productividadTotal[producto.nombreTipoOperacion] = productividadParcial;
  }

  obtenerMedidas(): Map<string, Map<string, Medida>> {
    return this.sumatoria.obtenerMedidas();
  }

  formatearNumero(valor: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;

    const allActivites = [...this.arregloOrdenadoActividadesConfirmadas, ...this.arregloOrdenadoActividadesEstimadas];

    if (this.incluirActividades) {
      allActivites.push(...this.sofConfirmadas, ...this.sofEstimadas);
    }

    const { confirmedActivities, estimatedActivites } = this.reportesService.combinePortLogsAndSOFActivities(
      allActivites,
      this.incluirActividades
    );

    this.arregloOrdenadoActividadesConfirmadas = [...confirmedActivities];
    this.arregloOrdenadoActividadesEstimadas = [...estimatedActivites];
  }

  obtenerTiposDeOperacion(): string[] {
    return Object.keys(this.operaciones);
  }

  getTiposTotales(tipoOperacion: string): string[] {
    return Array.from(this.obtenerMedidas().get(tipoOperacion).keys());
  }

  includeHATCH() {
    this.showHatch = !this.showHatch;
  }

  hasOnDeckProduct(hold: any): boolean {
    return hold.products.some((product) => product.OnDeck);
  }
}
