import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ViajeDetalleService } from '../../viaje-detalle.service';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import Swal from 'sweetalert2';
import { nuevoViaje } from '../../../../../interfaces/requests.interface';
import * as moment from 'moment';
import { CustomButtonComponent } from './custom-button.component';

@Component({
  selector: 'crear-proforma-card-viaje',
  templateUrl: './crear-proforma-card-viaje.component.html',
  styleUrls: ['./crear-proforma-card-viaje.component.scss'],
})
export class CrearProformaCardViajeComponent implements OnInit {
  @Input('confirmed') confirmed: boolean;
  /** Referencia del permiso de poder editar o solo ver */
  @Input('available') available: boolean;

  @Output() cambioEnProformas: EventEmitter<any> = new EventEmitter<any>();

  /** Viaje de la proforma */
  @Input('viaje') viaje: nuevoViaje;
  /** Indica si el modo actual es el de crear */
  public modoCrear: boolean = true;
  public modoCrearLiquidacion: boolean = true;
  /** Datos de la proforma */
  public proforma: any = '';
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;

  public liquidacion: any = '';

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  private permisosUsuario: any;

  /** Referencia al detalle de la proforma */
  @ViewChild('proformaWindow', { static: true }) proformaWindow: ElementRef;
  /** Referencia al detalle de la liquidacion */
  @ViewChild('liquidacionWindow', { static: true }) liquidacionWindow: ElementRef;

  @Output() liquidationChanging: EventEmitter<any> = new EventEmitter<any>();

  public proformas = [];

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        /*  {
          name: 'liquidacion',
          title: '<i class="nb-bar-chart"></i>'
        },
        {
          name: 'eliminar-liquidacion',
          title: '<i class="nb-trash"></i>'
        } */
        // { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      clientName: {
        title: 'Cliente',
      },
      hoursAnchorage: {
        title: 'Hours Anchorage',
      },
      hoursDockage: {
        title: 'Hours Dockage',
      },
      total: {
        title: 'Total Proformado USD',
      },
      eta: {
        title: 'ETA',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etb: {
        title: 'ETB',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etc: {
        title: 'ETC',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etd: {
        title: 'ETD',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      createdAt: {
        title: 'Created At',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      acciones: {
        title: 'Liquidaciones',
        type: 'custom',
        renderComponent: CustomButtonComponent,
        onComponentInitFunction: (instance: CustomButtonComponent) => {
          instance.action.subscribe((event) => {
            this.onCustom(event); // Enlaza con el método onCustom para manejar las acciones
          });
        },
        filter: false,
        sort: false,
      },
    },
  };

  public onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.proforma = event.data;
        this.onCrearEditarVerProforma();
        break;
      case 'liquidacion':
        this.liquidacion = event.data;
        this.abrirLiquidacionModal();
        break;
      case 'eliminar-liquidacion':
        this.liquidacion = event.data;
        this.onDeleteLiquidacion();
        break;
    }
  }

  constructor(
    private mainService: MainService,
    private viajeDetalleService: ViajeDetalleService,
    private dialogService: NbDialogService,
    public router: Router
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.viaje && changes.viaje.currentValue && this.viaje.recaladas) {
      this.viaje = changes.viaje.currentValue;
      this.cargando = true;
      this.confirmed = this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos.eta ? true : false;
      if (this.viaje.status == 'CANCELED') this.confirmed = false;

      this.initializeData();
    }

    this.getProforma();
  }

  /**
   * Inicializa los datos de la BD
   */
  initializeData() {
    this.cargando = true;
    this.proforma = '';
    this.getProforma();
    this.obtenerPermisos();
  }

  async getLiquidacion() {
    const client = this.liquidacion.client;
    const idProforma = this.liquidacion._id;
    const res = await this.mainService
      .get(`api/liquidacion?activo=true&idProforma=${this.liquidacion._id}`)
      .toPromise();
    if (res && res.length > 0) {
      this.liquidacion = res[0];
      this.liquidacion.client = client;
      this.liquidacion.idProforma = idProforma;
      this.modoCrearLiquidacion = false;
      // this.confirmed = true;
    } else {
      this.modoCrearLiquidacion = true;
    }
  }

  public async getProforma() {
    try {
      if (this.viaje.recaladas && this.viaje.recaladas.length === 0) return;

      const proformas = await this.mainService
        .get(`api/proforma?activo=true&recaladaId=${this.viaje.recaladas[0]._id}`)
        .toPromise();

      if (proformas.length === 0) return;

      for (const proforma of proformas) {
        const client = await this.mainService.get(`api/empresa_sql?activo=true&EMP_ID=${proforma.client}`).toPromise();
        if (client.length === 0) continue;
        proforma.clientName = client[0].EMP_NOMBRE;
        proforma.total = this.getTotalProforma(proforma.serviciosProforma).toFixed(2);
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        const formattedValue = formatter.format(proforma.total);
        proforma.total = formattedValue;
      }

      this.proforma = proformas[0];
      this.proformas = proformas;
      this.modoCrear = false;
    } catch (err) {
    } finally {
      this.cargando = false;
    }
  }

  public getTotalProforma(services: []): number {
    return services.reduce((accumulator, service: any) => {
      return accumulator + service.valor;
    }, 0);
  }

  /**
   * Cambia internamente la proforma actual
   * @param proforma Nueva proforma actual
   */
  proformaCambiada(proforma) {
    this.proforma = proforma;
    this.modoCrear = false;

    this.getProforma();
    this.cambioEnProformas.emit();
  }

  /**
   * Inicializa la creación o edición de una proforma
   */
  onCrearEditarVerProforma() {
    this.openCrearProformaConNominacionDialog(this.proformaWindow);
  }

  async obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.liquidaciones === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      }
    });
  }

  async abrirLiquidacionModal() {
    await this.getLiquidacion();
    if (this.liquidacion.serviciosLiquidacion) {
      this.liquidacion.serviciosProforma = this.liquidacion.serviciosLiquidacion;
    }
    this.openCrearLiquidacionConNominacionDialog(this.liquidacionWindow);
  }

  openCrearLiquidacionConNominacionDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  public onCreateProforma() {
    this.modoCrear = true;
    this.openCrearProformaConNominacionDialog(this.proformaWindow);
  }

  /**
   * Abre el dialogo con el detalle de la proforma
   * @param dialog Referencia al dialogo del detalle
   */
  openCrearProformaConNominacionDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Borra la proforma de la BD
   */
  onDeleteProforma() {
    Swal.fire({
      title: '<strong>¿Deseas eliminar la proforma?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/proforma/' + this.proforma._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'Proforma eliminada con éxito', 'success');
            this.proforma = '';
            this.modoCrear = false;
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando la proforma', 'warning');
      }
    });
  }

  async onDeleteLiquidacion() {
    const result = await Swal.fire({
      title: '<strong>¿Deseas eliminar la liquidación?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    });

    if (result.value) {
      try {
        const res = await this.mainService
          .get(`api/liquidacion?activo=true&idProforma=${this.liquidacion._id}`)
          .toPromise();
        if (res && res.length > 0) {
          this.liquidacion = res[0];
          this.mainService.delete(`api/liquidacion/${this.liquidacion.nv}/${this.liquidacion._id}`).subscribe(
            (res) => {
              if (res && res.message) {
                Swal.fire({
                  title: '¡Error!',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'Liquidación eliminada con éxito', 'success');
              this.liquidacion = '';
              this.modoCrear = true;
              this.liquidationChanging.emit();
            },
            (err) => {
              Swal.fire({
                title: '¡Error!',
                text: err.error.message,
                type: 'error',
              });
            }
          );
        }
      } catch (err) {
        Swal.fire({
          title: '¡Error!',
          text: err.message || 'Hubo un problema al obtener la liquidación.',
          type: 'error',
        });
      }
    }
  }
}
