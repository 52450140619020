import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import { TimeService } from '../../../../services/time/time.service';

import * as _ from 'lodash';
import Swal from 'sweetalert2';
@Component({
  selector: 'producto-viaje-detalle',
  templateUrl: './producto-viaje-detalle.component.html',
  styleUrls: ['./producto-viaje-detalle.component.scss'],
})
export class ProductoViajeDetalleComponent implements OnInit {
  /** Referencia al dialogo del detalle */
  @Input() dialogRef: any;
  /** Modo de la acción actual del detalle */
  @Input() modoActual: string;
  /** Viaje del producto */
  @Input() viaje: any;
  /** Producto viaje del modulo */
  @Input() productoViaje: any;
  /** fecha minima que se puede colocar */
  @Input() minDate: Date;
  /** Evento que indica que se realizo algun cambio en la tabla de productos de la BD */
  @Output() cambioEnProductosViaje: EventEmitter<any> = new EventEmitter<any>();

  @Input() holdsUsed = [];

  /** Arreglo con los posibles modos del detalle */
  public modosPosibles: string[] = ['CREAR', 'EDITAR'];
  /** Formulario reactivo para un contacto*/
  public formulario: FormGroup;
  /** Indica que el modulo se esta cargando */
  public cargando: boolean = false;
  /** Todos los productos sin excepción */
  public allProducts: any[] = [];
  /** Productos en la BD */
  public productos: any[] = [];
  /** Indica que los productos se estan cargando */
  public productosCargandose;
  /** Medidas en la BD */
  public medidas: any;
  /** Indica que las medidas se estan cargando */
  public medidasCargandose: boolean;
  /** Tipos de operacion en la BD */
  public tiposDeOperaciones: any;
  /** Indica que los tipos de operaciones se estan cargando */
  public tiposDeOperacionesCargandose: boolean;
  /** Terminales en la BD */
  public terminales: any;
  /** Indica que las terminales se estan cargando */
  public terminalesCargandose: boolean;
  /** Commodities en la BD */
  public commodities: any;
  /** Indica que los commodities se estan cargando */
  public commoditiesCargandose: boolean;
  /** ID del producto */
  public PRV_ID: number;

  public holdNumbers = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'H7', 'H8', 'H9', 'H10', 'H11', 'H12', 'H13', 'H14', 
                        'H15', 'H16', 'H17', 'H18', 'H19', 'H20'];

  constructor(
    private mainService: MainService,
    public dateAndTimeService: DateAndTimeService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    // Cargar datos de BD
    this.onCargarMedidas();
    this.onCargarTiposDeOperacion();
    this.onCargarTerminales();
    this.onCargarCommodities();
    this.modoActual == this.modosPosibles[0] ? this.obtenerIdProductoViaje() : this.fechasEnFormatoFomulario();
    this.inicializarFormulario();

    if (this.modoActual === 'CREAR') {
      this.formulario.patchValue({
        TOP_ID: this.viaje.recaladas[0].operationType.id,
        TER_ID: this.viaje.recaladas[0].terminal.id,
        COMMODITY_ID: this.viaje.commodity.id,
      });

      this.onCargarProductos();
    }
  }

  /**
   * Obtiene los productos de la BD
   */
  onCargarProductos(): void {
    this.productosCargandose = true;
    this.mainService.get(`api/producto/commodity/${this.viaje.commodity.id}`).subscribe((res) => {
      const productos = res;
      this.allProducts = productos;
      this.productos = productos;
      this.productosCargandose = false;
    });
  }

  /**
   * Obtiene las medidas de la BD
   */
  onCargarMedidas(): void {
    this.medidasCargandose = true;

    this.mainService.get('api/medida?activo=true').subscribe((res) => {
      this.medidas = res;
      this.medidas = _.orderBy(this.medidas, ['MED_NOMBRE']);

      this.medidasCargandose = false;
    });
  }

  /**
   * Obtiene los tipos de operacion de la BD
   */
  onCargarTiposDeOperacion(): void {
    this.tiposDeOperacionesCargandose = true;

    this.mainService.get('api/operacion?activo=true').subscribe((res) => {
      this.tiposDeOperaciones = res;
      this.tiposDeOperaciones = _.orderBy(this.tiposDeOperaciones, ['TOP_NOMBRE']);

      this.tiposDeOperacionesCargandose = false;
    });
  }

  /**
   * Obtiene las terminales de la BD
   */
  onCargarTerminales(): void {
    this.terminalesCargandose = true;

    this.mainService.get('api/terminal?activo=true').subscribe((res) => {
      this.terminales = res;
      this.terminales = _.orderBy(this.terminales, ['TER_NOMBRE']);

      this.terminalesCargandose = false;
    });
  }

  /**
   * Obtiene los tipos de operacion de la BD
   */
  onCargarCommodities(): void {
    this.commoditiesCargandose = true;

    this.mainService.get('api/commodity?activo=true').subscribe((res) => {
      this.commodities = res;
      this.commodities = _.orderBy(this.commodities, ['COMODITY']);
      this.commoditiesCargandose = false;

      this.onCargarProductos();
    });
  }

  /**
   * Obiene el numero consecutivo en el que se encuentra actualmente los IDs de los productos-viajes
   */
  obtenerIdProductoViaje(): void {
    this.mainService.get('api/global_variable/PRV_ID').subscribe((res) => {
      // Cuando la variable global no ha sido creada llega un mensaje
      if (res.message) {
        this.PRV_ID = 24000; //Primer valor del PRV_ID
        const idProducto = {
          key: 'PRV_ID',
          value: this.PRV_ID,
        };
        this.mainService.post('api/global_variable', idProducto).subscribe((result) => {});
      } else {
        this.PRV_ID = res.value;
      }
    });
  }

  /**
   * Obtiene las fechas en el formato del formulario
   */
  fechasEnFormatoFomulario(): void {
    const TIME_FROM = this.timeService.getSplitDate(this.productoViaje.PRV__TIME_FROM);
    const TIME_TO = this.timeService.getSplitDate(this.productoViaje.PRV__TIME_TO);

    // TIME_FROM
    if (this.productoViaje && this.productoViaje.PRV__TIME_FROM) {
      this.productoViaje.PRV__TIME_FROMFecha = TIME_FROM.date;
      this.productoViaje.PRV__TIME_FROMHora = TIME_FROM.hour;
      this.productoViaje.PRV__TIME_FROMMinuto = TIME_FROM.minutes;
    }

    // TIME_TO
    if (this.productoViaje && this.productoViaje.PRV__TIME_TO) {
      this.productoViaje.PRV__TIME_TOFecha = TIME_TO.date;
      this.productoViaje.PRV__TIME_TOHora = TIME_TO.hour;
      this.productoViaje.PRV__TIME_TOMinuto = TIME_TO.minutes;
    }
  }

  inicializarFormulario() {
    this.formulario = new FormGroup({
      nv: new FormControl(
        { value: this.productoViaje && this.productoViaje.nv ? this.productoViaje.nv : this.viaje.nv, disabled: true },
        Validators.required
      ),
      REC_ID: new FormControl({ value: this.viaje.recaladas[0]._id, disabled: false }, Validators.required),
      PRV__TIME_FROMFecha: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV__TIME_FROMFecha ? this.productoViaje.PRV__TIME_FROMFecha : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV__TIME_FROMHora: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV__TIME_FROMHora ? this.productoViaje.PRV__TIME_FROMHora : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV_ABV: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.PRV_ABV ? this.productoViaje.PRV_ABV : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV__TIME_FROMMinuto: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV__TIME_FROMMinuto
              ? this.productoViaje.PRV__TIME_FROMMinuto
              : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV__TIME_TOFecha: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.PRV__TIME_TOFecha ? this.productoViaje.PRV__TIME_TOFecha : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV__TIME_TOHora: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.PRV__TIME_TOHora ? this.productoViaje.PRV__TIME_TOHora : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV__TIME_TOMinuto: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV__TIME_TOMinuto ? this.productoViaje.PRV__TIME_TOMinuto : '',
          disabled: false,
        },
        Validators.required
      ),
      PRD_ID: new FormControl(
        { value: this.productoViaje && this.productoViaje.PRD_ID ? this.productoViaje.PRD_ID : '', disabled: false },
        Validators.required
      ),
      PRV_ACUMULADO: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV_ACUMULADO != undefined ? this.productoViaje.PRV_ACUMULADO : 0,
          disabled: false,
        },
        Validators.required
      ),
      PRV_PROD_12H: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV_PROD_12H != undefined ? this.productoViaje.PRV_PROD_12H : '',
          disabled: false,
        },
        Validators.required
      ),
      PRV_CANTIDAD: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.PRV_CANTIDAD != undefined ? this.productoViaje.PRV_CANTIDAD : 0,
          disabled: false,
        },
        Validators.required
      ),
      PRV_ROB: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.PRV_ROB != undefined ? this.productoViaje.PRV_ROB : 0,
          disabled: false,
        },
        Validators.required
      ),
      MED_ID: new FormControl(
        { value: this.productoViaje && this.productoViaje.MED_ID ? this.productoViaje.MED_ID : '', disabled: false },
        Validators.required
      ),
      TOP_ID: new FormControl(
        { value: this.productoViaje && this.productoViaje.TOP_ID ? this.productoViaje.TOP_ID : '', disabled: false },
        Validators.required
      ),
      TER_ID: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.TERMINAL ? this.productoViaje.TERMINAL : '',
          disabled: false,
        },
        Validators.required
      ),
      COMMODITY_ID: new FormControl(
        {
          value: this.productoViaje && this.productoViaje.COMMODITY_ID ? this.productoViaje.COMMODITY_ID : '',
          disabled: false,
        },
        Validators.required
      ),
      HOLD_NUMBER: new FormControl(
        {
          value:
            this.productoViaje && this.productoViaje.HOLD_NUMBER != undefined ? this.productoViaje.HOLD_NUMBER : '',
          disabled: false,
        },
        Validators.required
      ),
    });
  }

  /**
   * Maneja el evento de mandar los datos del formualrio
   */
  onEnviarFormulario() {
    this.cargando = true;

    if (!this.formulario.valid) {
      Swal.fire({
        title: `No se puede ${this.modoActual.toLocaleLowerCase()} el producto`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
    } else {
      const datosDeSalida: any = this.obtenerDatosDeSalida();
      datosDeSalida.VIA_ID = this.viaje._viaId;
      const opsTime = new Date(this.viaje.portLog.completionOPSTime.fecha.slice(0, -1));
      const prvTime = new Date(this.formulario.value.PRV__TIME_TOFecha + `T${this.formulario.value.PRV__TIME_TOHora}:${this.formulario.value.PRV__TIME_TOMinuto}:00.000`);
      if(prvTime >= opsTime) {
        Swal.fire('Error', 'La fecha Hasta debe ser menor a Completion OPS Time', 'warning');
        this.cargando = false;
        return;
      }

      if (new Date(datosDeSalida.PRV__TIME_TO).getTime() < new Date(datosDeSalida.PRV__TIME_FROM).getTime()) {
        Swal.fire({
          title: `No se puede ${this.modoActual.toLocaleLowerCase()} el producto`,
          text: 'La fecha hasta no puede ser menor a la fecha desde',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.cargando = false;
      } else if (datosDeSalida.PRV__TIME_TO < this.minDate || datosDeSalida.PRV__TIME_FROM < this.minDate) {
        Swal.fire({
          title: `No se puede ${this.modoActual.toLocaleLowerCase()} el producto`,
          text: 'La fecha no puede ser menor a la fecha de inicio de operaciones',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.cargando = false;
      } else {
        if (this.modoActual == this.modosPosibles[0]) {
          this.mainService.post('api/producto_completo', datosDeSalida).subscribe((productoResult) => {
            if (productoResult) {
              this.cambioEnProductosViaje.emit(productoResult);
              this.mainService.put('api/global_variable/PRV_ID', {}).subscribe((result) => {});

              const nuevoProducto = productoResult;
              // this.mainService.get(`api/nuevo-viaje/${this.viaje._id}`).subscribe((viajeResult) => {
              //   this.viaje = viajeResult;
              //   let productos = viajeResult && viajeResult.recaladas[0].productos;
              //   productos.push(nuevoProducto._id);

              //   // this.viaje.recaladas[0].productos.push(productoResult._id);
              //   delete this.viaje.sof;

              //   this.mainService
              //     .put(`api/nuevo-viaje/${this.viaje._id}`, { viaje: this.viaje, recalada: this.viaje.recaladas[0] })
              //     .subscribe((result) => {
              //       this.cargando = false;
              //       Swal.fire('Éxito', 'Se agregó el producto exitosamente', 'success').then((result) => {
              //         if (result.value) {
              //           this.cerrarDialog();
              //         }
              //       });
              //     });
              // });

              this.viaje.recalada.productos.push(nuevoProducto._id);
              this.mainService.put(`api/recalada/${this.viaje.recalada._id}`, this.viaje.recalada).subscribe((res) => {
                this.mainService.get(`api/nuevo-viaje/${this.viaje._id}`).subscribe((viajeResult) => {
                  this.viaje = viajeResult;

                  Swal.fire('Éxito', 'Se agregó el producto exitosamente', 'success').then((result) => {
                    if (result.value) {
                      this.cerrarDialog();
                    }
                  });
                });
              });
            } else {
              Swal.fire('Error', 'No se pudo agregar el producto, verifique la información', 'warning');
            }
          });
        } else {
          this.mainService.put(`api/producto_completo/${this.productoViaje._id}`, datosDeSalida).subscribe((result) => {
            if (result) {
              this.cambioEnProductosViaje.emit(result);
              this.cargando = false;
              Swal.fire('Éxito', 'Se actualizó el producto exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.cerrarDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo actualizar el producto, verifique la información', 'warning');
            }
          });
        }
      }
    }
  }

  public updateValuesByProductivity(event) {
    let acum = parseFloat(this.formulario.value.PRV_PROD_12H);

    if (this.modoActual === 'EDITAR') {
      acum += parseFloat(this.formulario.get('PRV_ACUMULADO').value);
    }

    this.formulario.patchValue({
      PRV_ACUMULADO: acum.toFixed(2),
      PRV_ROB: (parseFloat(this.formulario.get('PRV_CANTIDAD').value) - acum).toFixed(2),
    });
  }

  /**
   * Coge los datos del modulo y los pone en el formato JSON como deben ser guardados en la BD
   */
  obtenerDatosDeSalida(): any {
    const id = this.PRV_ID ? `${this.PRV_ID}` : `${this.productoViaje.PRV_ID}`;

    const datosDeSalida = {
      nv: this.viaje && this.viaje.nv ? this.viaje.nv : null,
      PRV_ABV: this.formulario.value.PRV_ABV,
      REC_ID: this.formulario.value.REC_ID,
      PRV_ID: id ? id : null,
      PRV__TIME_FROM: this.timeService.convertStringDateToDate(
        this.formulario.value.PRV__TIME_FROMFecha,
        this.formulario.value.PRV__TIME_FROMHora,
        this.formulario.value.PRV__TIME_FROMMinuto
      ),
      PRV__TIME_TO: this.timeService.convertStringDateToDate(
        this.formulario.value.PRV__TIME_TOFecha,
        this.formulario.value.PRV__TIME_TOHora,
        this.formulario.value.PRV__TIME_TOMinuto
      ),
      PRD_ID: this.formulario.value.PRD_ID ? this.formulario.value.PRD_ID : null,
      PRV_ACUMULADO:
        this.formulario.value.PRV_ACUMULADO || this.formulario.value.PRV_ACUMULADO == 0
          ? this.formulario.value.PRV_ACUMULADO
          : 0,
      VIA_ID: this.viaje && this.viaje.VIA_ID ? this.viaje.VIA_ID : null,
      PRV_PROD_12H:
        this.formulario.value.PRV_PROD_12H || this.formulario.value.PRV_PROD_12H == 0
          ? this.formulario.value.PRV_PROD_12H
          : null,
      PRV_CANTIDAD:
        this.formulario.value.PRV_CANTIDAD || this.formulario.value.PRV_CANTIDAD == 0
          ? this.formulario.value.PRV_CANTIDAD
          : 0,
      PRV_ROB: this.formulario.value.PRV_ROB || this.formulario.value.PRV_ROB == 0 ? this.formulario.value.PRV_ROB : 0,
      MED_ID: this.formulario.value.MED_ID ? this.formulario.value.MED_ID : null,
      TOP_ID: this.formulario.value.TOP_ID ? this.formulario.value.TOP_ID : null,
      TERMINAL: this.formulario.value.TER_ID ? this.formulario.value.TER_ID : null,
      CAR_ID: this.formulario.value.CAR_ID ? this.formulario.value.CAR_ID : null,
      COMMODITY_ID: this.formulario.value.COMMODITY_ID ? this.formulario.value.COMMODITY_ID : null,
      HOLD_NUMBER:
        this.formulario.value.HOLD_NUMBER || this.formulario.value.HOLD_NUMBER == 0
          ? this.formulario.value.HOLD_NUMBER
          : null,
    };

    return datosDeSalida;
  }

  changedCommodity(event) {
    this.productos = this.allProducts.filter((product) => {
      return product.COMODITY_ID == event.target.value;
    });
    this.productos = _.orderBy(this.productos, ['PRD_NOMBRE']);
  }

  /** Cierra el detalle del producto viaje */
  cerrarDialog(): void {
    this.dialogRef.close();
  }
}
