export type Medida = {
    tipo: string;
    totalAmount: number;
    totalProductivity: number;
    totalAcumulated: number;
    totalROB: number;
};

export class Sumatoria {
    private medidas: Map<string, Medida>;

    constructor() {
        this.medidas = new Map<string, Medida>();
    }

    reiniciar(): void {
        this.medidas.clear();
    }

    agregarMedida(medida: Medida): void {
        if (this.medidas.has(medida.tipo)) {
            const medidaActual = this.medidas.get(medida.tipo);
            if (medidaActual) {
                medidaActual.totalAmount += medida.totalAmount;
                medidaActual.totalProductivity += medida.totalProductivity;
                medidaActual.totalAcumulated += medida.totalAcumulated;
                medidaActual.totalROB += medida.totalROB;
            }
        } else {
            this.medidas.set(medida.tipo, { ...medida });
        }
    }

    obtenerMedidas(): Map<string, Medida> {
        return this.medidas;
    }
}