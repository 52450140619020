import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { MainService } from '../services/main.service';
import { ViajesServiceService } from '../services/viajes/viajes-service.service';
import { TimeService } from '../../services/time/time.service';

@Component({
  selector: 'app-viajes',
  templateUrl: './viajes.component.html',
  styleUrls: ['./viajes.component.scss'],
})
export class ViajesComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: ElementRef;

  /** Lista de empresas para obtener el charterer y disponent owner */
  public businesses: any[];
  /** Lista de terminales */
  public terminales: any[];
  /** Viajes en la BD */
  public viajes: any = [];
  public viajesTable = [];
  public recaladaViaje: string = null;
  private modalRecalada;

  /** Informacion del usuario actual */
  public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));
  /** Indica los permisos que tiene el usuario */
  public permisosUsuario: any;
  /** Configuraciones de la tabla */

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private viajesService: ViajesServiceService,
    private timeService: TimeService
  ) {}

  readonly settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: 'viewAndEdit', title: '<i class="nb-compose"></i>' }],
    },
    columns: {
      nv: {
        title: 'NV',
        sortDirection: 'desc',
        compareFunction: (order, a, b) => {
          const aValue = parseInt(a.match(/^(\d+)/)[0]);
          const bValue = parseInt(b.match(/^(\d+)/)[0]);

          const value = (aValue - bValue) * order;

          if (value == 0) {
            const aValueInsideParentheses = parseInt(a.match(/\((\d+)\)/)[1]);
            const bValueInsideParentheses = parseInt(b.match(/\((\d+)\)/)[1]);

            return (aValueInsideParentheses - bValueInsideParentheses) * order;
          }

          return value;
        },
      },
      statusExtra: {
        title: 'Status',
      },
      vesselName: {
        title: 'Buque',
      },
      voyageNumber: {
        title: 'Viaje',
      },
      nombrePuerto: {
        title: 'Puerto',
      },
      terminal: {
        title: 'Terminal',
      },
      nombreNominador: {
        title: 'Nominador',
      },
      charterer: {
        title: 'Fletador',
      },
      disponentOwner: {
        title: 'Armador',
      },
      eta: {
        title: 'ETA',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
      etb: {
        title: 'ETB',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
      etc: {
        title: 'ETC',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
      etd: {
        title: 'ETD',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
      atd: {
        title: 'ATD',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
      status: {
        title: 'Estatus',
      },
      createdAt: {
        title: 'Fecha de creación',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  ngOnInit() {
    this.getAllViajes();
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.viajes == 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al modulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.getAllViajes();
      }
    });
  }

  public dataCollectionToFind = [];

  public setRecaladaViaje(data: string) {
    this.recaladaViaje = data;
  }

  getAllViajes() {
    this.viajesService.getAllViajes().then((response: any) => {
      this.viajes = response.filter((viaje) => viaje.activo);
      this.viajesTable = [];

      this.dataCollectionToFind = [];
      this.viajes.forEach((viaje) => {
        this.dataCollectionToFind.push({
          key: viaje._id,
          value: `${viaje.nv} - ${viaje.vessel.nombre} - ${viaje.voyageNumber || ''} - ${viaje.port.nombre}
            `,
        });
      });

      response.forEach((viaje) => {
        viaje.recaladas.forEach((recalada) => {
          const templateViaje = { ...viaje };
          templateViaje.nv = `${templateViaje.nv}(${recalada.index})`;
          templateViaje.vesselName = viaje.vessel.nombre;
          templateViaje.recaladas = [];
          templateViaje.recaladas = [recalada];
          templateViaje.nombrePuerto = viaje.port.nombre;
          templateViaje.terminal = recalada.terminal.nombre;
          templateViaje.nombreNominador = viaje.appointment.nombre;
          templateViaje.charterer = viaje.charterer.nombre;
          templateViaje.disponentOwner = viaje.disponentOwner.nombre;

          templateViaje.eta = recalada.prospectos && recalada.prospectos.eta;
          templateViaje.etb = recalada.prospectos && recalada.prospectos.etb;
          templateViaje.etc = recalada.prospectos && recalada.prospectos.etc;
          templateViaje.etd = recalada.prospectos && recalada.prospectos.etd;
          templateViaje.atd = recalada.prospectos && recalada.prospectos.atd;

          templateViaje.createdAt = viaje.createdAt;
          templateViaje.statusExtra = recalada.informacionExtra && recalada.informacionExtra.statusExtra
          this.viajesTable.push(templateViaje);
        });
      });
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'viewAndEdit':
        this.viewAndEdit(event);
        break;
    }
  }

  crearBarco() {
    this.router.navigate(['home/viaje/create']);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  onDelete(event) {
    if (this.permisosUsuario.viajes == 'LECTURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el viaje?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          if (event.data.statusExtra === 'BERTHED') {
            Swal.fire({
              title: '¡Error!',
              text: 'No se puede eliminar un viaje que tenga status berthed.',
              type: 'error',
            });
          } else if (
            event.data.recaladas[0].portLog.eosp &&
            event.data.recaladas[0].portLog.eosp.status === 'CONFIRMED'
          ) {
            Swal.fire({
              title: '¡Error!',
              text: 'No se puede eliminar un viaje que tenga un EOSP confirmado.',
              type: 'error',
            });
          } else {
            const recaladaId = event.data.recaladas[0]._id;
            this.mainService.delete(`api/nuevo-viaje/${event.data._id}/${recaladaId}`).subscribe((res) => {
              if (res) {
                Swal.fire('Éxito', 'Viaje eliminado con éxito', 'success');
                this.getAllViajes();
              }
            });
          }
        }
      });
    }
  }

  viewAndEdit(event) {
    this.router.navigate([`home/viaje/view-and-edit/${event.data._id}/${event.data.recaladas[0]._id}`]);
  }

  showUpAddRecalada() {
    const dialog = this.dialog as any;
    this.modalRecalada = this.dialogService.open(dialog, { context: 'this is some additional data' });
  }

  redirectCrearRecalada() {
    if (!this.recaladaViaje)
      return Swal.fire({
        title: '¡Error!',
        text: 'Selecciona un NV para crear una nueva recalada.',
        type: 'error',
      });

    this.modalRecalada.close();
    this.router.navigate([`home/viaje/create-recalada/${this.recaladaViaje}`]);
  }
}
