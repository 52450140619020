import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { MainService } from '../../public/services/main.service';
import { AlertService } from '../../services/alertService/alert-service.service';
import { RolService } from '../../services/rol/rol.service';
import { NbDialogService } from '@nebular/theme';

import SofFormatOptions from '../../interfaces/enums/SofFormatOptions.enum';

import _ from 'lodash';
import { TimeService } from '../../services/time/time.service';

interface SOFFormat {
  _id: string;
  name: string;
  rainStops: boolean;
  header: string[];
  order: string[];
  footer: string[];
  active: boolean;
  activeView: string;
  createdAt: Date;
  createdAtView: string;
}

interface SOFFormatForm {
  name?: string;
  order?: string[];
  active?: boolean;
  rainStops?: boolean;
}

interface PageSetting {
  formMode: FormMode;
  loading: boolean;
}

enum FormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

@Component({
  selector: 'app-sof-format',
  templateUrl: './sof-format.component.html',
  styleUrls: ['./sof-format.component.scss'],
})
export class SofFormatComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialogRef: ElementRef;
  private dialog: any;

  public SOFFormats: SOFFormat[] = [];
  public addedRainStops = false;
  // form info states
  private sofFormatElementsDefaults = [
    SofFormatOptions.HEADER,
    SofFormatOptions.SUBHEADER,
    SofFormatOptions.PORTLOG,
    SofFormatOptions.ZARPEPORTLOG,
    SofFormatOptions.ARRIBOPORTLOG,
    SofFormatOptions.ARRIVALDEPARTURE,
    SofFormatOptions.ARRIVAL,
    SofFormatOptions.DEPARTURE,
    SofFormatOptions.PRIORITYREMARKS,
    SofFormatOptions.ALLREMARKS,
    SofFormatOptions.TOTALSTOPPEDHOURS,
    SofFormatOptions.TOTALSTOPPEDOPERATIONS,
    SofFormatOptions.MASTERREMARK,
    SofFormatOptions.AGENCYREMARK,
    SofFormatOptions.TERMINALREMARK,
    SofFormatOptions.FOOTER,
  ];
  public sofFormatElements = [...this.sofFormatElementsDefaults];

  private sofFormTemplate = {
    _id: undefined,
    name: undefined,
    rainStops: undefined,
    header: [SofFormatOptions.HEADER, SofFormatOptions.SUBHEADER],
    order: [],
    footer: [SofFormatOptions.FOOTER],
    active: undefined,
    activeView: undefined,
    createdAt: undefined,
    createdAtView: undefined,
  };

  public sofFormatForm: SOFFormat = _.cloneDeep(this.sofFormTemplate);
  private sofFormatFormDefault: SOFFormat = _.cloneDeep(this.sofFormTemplate);
  private sofFormatFormDB: SOFFormatForm = {};

  // settings
  public FormMode = FormMode;
  public pageSetting: PageSetting = {
    formMode: FormMode.CREATE,
    loading: false,
  };

  public settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i>' },
        { name: 'delete', title: '<i class="nb-trash"></i>' },
      ],
    },
    columns: {
      name: {
        title: 'Nombre',
      },
      activeView: {
        title: 'Estado',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  private onCustomSelect = {
    edit: this.editSOFFormat,
    delete: this.removeSOFFormat,
  };

  public dragging: boolean = false;
  constructor(
    private mainService: MainService,
    private dialogService: NbDialogService,
    private alertService: AlertService,
    private rolService: RolService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.rolChecking();
    this.getAllSOFFormats();
  }

  private async rolChecking() {
    await this.rolService.checkRolAccess('sofFormat');
    this.rolService.checkByPermissions('accces');
  }

  public async getAllSOFFormats() {
    this.pageSetting.loading = true;

    try {
      const SOFFormats = await this.mainService.get('api/sof-formats').toPromise();
      this.SOFFormats = SOFFormats;
    } catch (err) {}

    this.pageSetting.loading = false;
  }

  public createSOFFormat() {
    if (!this.rolService.checkByPermissions('writing')) return;

    this.unpopulateForm();
    this.removingSofFormatElements();
    this.pageSetting.formMode = this.FormMode.CREATE;
    this.showUpDialog();
  }

  private editSOFFormat() {
    this.pageSetting.formMode = this.FormMode.EDIT;
    this.removingSofFormatElements();
    this.showUpDialog();
  }

  // crud section
  public async postSOFFormat() {
    try {
      await this.mainService.post('api/sof-format', this.sofFormatForm).toPromise();
      await this.getAllSOFFormats();
      this.hideDialog();
      this.unpopulateForm();

      this.alertService.simpleAlertConfirm('Formato SOF creado');
    } catch (err) {
      this.alertService.simpleAlertError('No fue posible crear el Formato SOF');
    }
  }

  public async patchSOFFormat() {
    try {
      await this.mainService.patch(`api/sof-format/${this.sofFormatForm._id}`, this.sofFormatFormDB).toPromise();
      await this.getAllSOFFormats();
      this.hideDialog();
      this.unpopulateForm();

      this.alertService.simpleAlertConfirm('Formato SOF actualizado');
    } catch (err) {
      this.alertService.simpleAlertError('No fue posible actualizar el Formato SOF');
    }
  }

  private async removeSOFFormat() {
    try {
      const result = await this.alertService.optionsAlertWarning('¿Desea eliminar el formato SOF?');
      if (result.dismiss) return;

      await this.mainService.delete(`api/sof-format/${this.sofFormatForm._id}`).toPromise();
      await this.getAllSOFFormats();
      this.unpopulateForm();

      this.alertService.simpleAlertConfirm('Formato SOF eliminado');
    } catch (err) {
      this.alertService.simpleAlertError('No fue posible eliminar el Formato SOF');
    }
  }

  // table actions
  public onCustom(event) {
    if (!this.rolService.checkByPermissions('writing')) return;
    this.populateForm(event.data);
    this.onCustomSelect[event.action].bind(this)();
  }

  // dialog
  private showUpDialog() {
    this.dialog = this.dialogService.open(this.dialogRef as any);
  }

  public hideDialog() {
    this.populateSofFormatElements();
    this.dialog.close();
  }

  // form
  public onValueChange(keyValue: string, dataModified?: any) {
    this.sofFormatFormDB[keyValue] = dataModified || this.sofFormatForm[keyValue];
  }

  private populateForm(data: SOFFormat) {
    const clonedData = _.cloneDeep(data);
    this.sofFormatForm = clonedData;
  }

  private unpopulateForm() {
    this.sofFormatForm = _.cloneDeep(this.sofFormatFormDefault);
    this.sofFormatFormDB = {};
  }

  // drag and drop
  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.onValueChange('header', this.sofFormatForm.header);
    this.onValueChange('order', this.sofFormatForm.order);
    this.onValueChange('footer', this.sofFormatForm.footer);
  }

  acceptOnlyHeader(item: CdkDrag<string>) {
    return (
      item.element.nativeElement.textContent === SofFormatOptions.HEADER ||
      item.element.nativeElement.textContent === SofFormatOptions.SUBHEADER
    );
  }

  acceptOnlyFooter(item: CdkDrag<string>) {
    return item.element.nativeElement.textContent === SofFormatOptions.FOOTER;
  }

  private populateSofFormatElements() {
    this.sofFormatElements = _.cloneDeep(this.sofFormatElementsDefaults);
  }

  private removingSofFormatElements() {
    this.sofFormatElements = this.sofFormatElements.filter((element) => {
      return !(
        this.sofFormatForm.header.includes(element) ||
        this.sofFormatForm.order.includes(element) ||
        this.sofFormatForm.footer.includes(element)
      );
    });
  }
}
