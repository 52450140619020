enum SofFormatOptions {
  HEADER = 'Encabezado',
  SUBHEADER = 'Cabezote',
  PORTLOG = 'PortLogs',
  ALLPORTLOG = 'AllPortLogs',
  ARRIBOPORTLOG = 'Arrival Details',
  ZARPEPORTLOG = 'Departure Details',
  ARRIVALDEPARTURE = 'Arrival / Departure Conditions',
  ARRIVAL = 'Arrival Conditions',
  DEPARTURE = 'Departure Conditions',
  PRIORITYREMARKS = 'Priority Remarks',
  ALLREMARKS = 'All Remarks',
  TOTALSTOPPEDOPERATIONS = 'Total Stopped Operations',
  TOTALSTOPPEDHOURS = 'Total Stopped Hours',
  MASTERREMARK = 'Master Remarks',
  AGENCYREMARK = 'Agency Remarks',
  TERMINALREMARK = 'Terminal Remarks',
  FOOTER = 'Pie de página',
}

export default SofFormatOptions;
