import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MainService } from '../services/main.service';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss'],
})
export class TarifasComponent implements OnInit {
  /** Configuracion basica de la tabla */
  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'viewAndEdit', title: '<i class="nb-compose"></i>' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombreServicio: {
        title: 'Servicio',
      },
      nombrePuerto: {
        title: 'Puerto',
      },
      nombreTerminal: {
        title: 'Terminal',
      },
      nombreOperador: {
        title: 'Operador',
      },
      cliente: {
        title: 'Cliente',
      },
      clasificacionServicio: {
        title: 'Clasificación del servicio',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /** Referencia al dialogo del detall */
  public dialogRef: any;
  /** Tarifas en la BD */
  public tarifas: any;
  /** Puertos en la BD */
  public puertos: any;
  /** Terminales en la BD */
  public terminales: any;
  /** Empresas en la BD */
  public operadores: any;
  public clientes: any;
  /** Tarifa actualmente seleccionada */
  public tarifa: any;
  /** Indica si actualmente se esta en modo crear */
  public createMode: boolean;

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  /** Referencia al detalle de la tarifa */
  @ViewChild('detalleTarifa', { static: true }) detalleTarifa: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private router: Router, private timeService: TimeService) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.tarifas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.initializeData();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  initializeData() {
    this.mainService.get('api/terminal?activo=true').subscribe((resul) => {
      this.terminales = resul;

      this.mainService.get('api/puerto?activo=true').subscribe((resul) => {
        this.puertos = resul;

        this.mainService.get('api/empresa_sql?activo=true').subscribe((resul) => {
          this.operadores = resul;

          this.mainService.get('api/empresa_sql?&tipo=CLIENTE&activo=true').subscribe((resul) => {

            this.clientes = resul;

              this.mainService.get('api/tarifa?activo=true').subscribe((res) => {
                try {
                  this.tarifas = res;
                  this.tarifas.forEach((element) => {
                    const puertoAsociado = this.puertos.find(({ PUE_ID }) => PUE_ID == element.puerto);
                    if (puertoAsociado) {
                      element.nombrePuerto = puertoAsociado.PUE_NOMBRE == 'ALL' ? 'TODOS' : puertoAsociado.PUE_NOMBRE;
                    } else {
                      element.nombrePuerto = 'TODAS';
                    }

                    const terminalAsociada = this.terminales.find(({ TER_ID }) => TER_ID == element.terminal);
                    if (terminalAsociada) {
                      element.nombreTerminal =
                        terminalAsociada && terminalAsociada.TER_NOMBRE == 'ALL' ? 'TODAS' : terminalAsociada.TER_NOMBRE;
                    } else {
                      element.nombreTerminal = 'TODAS';
                    }

                    const operadorAsociado = this.operadores.find(({ EMP_ID }) => EMP_ID == element.operador);
                    element.nombreOperador = operadorAsociado ? operadorAsociado.EMP_NOMBRE : undefined;

                    const clienteAsociado = this.clientes.find(({ EMP_ID }) => EMP_ID == element.cliente);
                    element.cliente = clienteAsociado ? clienteAsociado.EMP_NOMBRE : undefined;
                  });
                  this.tarifas = _.orderBy(this.tarifas, ['createdAt'], ['desc']);
                } catch (err) {
                  console.error(err);
                }
              });
            });
        });
      });
    });
  }

  /**
   * Inicializa la creacion de una tarifa
   */
  onCrearTarifa() {
    this.createMode = true;
    this.openTarifaDialog(this.detalleTarifa);
  }

  /**
   * Abre el dialogo del detalle
   * @param dialog referencia al dialogo de detalle
   */
  openTarifaDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Evento con los datos de la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.onDelete(event);
        break;
      case 'viewAndEdit':
        this.viewAndEdit(event);
        break;
    }
  }

  /**
   * Inicializa la visualizacion o edicion de una tarifa
   * @param event Evento con los datos de la tarifa a ver o editar
   */
  viewAndEdit(event) {
    this.createMode = false;
    this.tarifa = event.data;
    this.openTarifaDialog(this.detalleTarifa);
  }

  /**
   * Elimina una tarifa
   * @param event Evento con los datos de la tarifa a eliminar
   */
  onDelete(event) {
    if (this.permisosUsuario.tarifas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar la tarifa?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          //this.loading = true;
          this.mainService.delete('api/tarifa/' + event.data._id).subscribe(
            (res) => {
              if (res) {
                if (res.message) {
                  Swal.fire({
                    title: '¡Error!',
                    text: res.message,
                    type: 'error',
                  });
                  return;
                }
                this.initializeData();
                Swal.fire('Éxito', 'Tarifa eliminada con éxito', 'success');
              }
            },
            (err) => {
              console.error(err);
              Swal.fire('Error', err.error.message, 'error');
            }
          );
        }
      });
    }
  }
}
