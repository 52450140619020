import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from '../services/main.service';
import { NbDialogService } from '@nebular/theme';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'liquidaciones',
  templateUrl: './liquidaciones.component.html',
  styleUrls: ['./liquidaciones.component.scss'],
})
export class LiquidacionesComponent implements OnInit {
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Liquidaciones traidas de la BD */
  public todasLasLiquidaciones: any = [];
  /** Liquidaciones con nominacion en la BD */
  public liquidacionesConNominacion: any = [];
  /** Configuracion de la tabla con liquidaciones */
  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'viewAndEdit', title: '<i class="nb-compose"></i>' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nv: {
        title: 'NV',
      },
      nombreBuque: {
        title: 'Buque',
      },
      nombrePuerto: {
        title: 'Puerto',
      },
      nombreTerminal: {
        title: 'Terminal',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Indica si el modo actual es de creacion */
  public modoCreacion: boolean = false;
  /** Liquidacion actualmente seleccionada */
  public liquidacion: any = '';
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;
  /** Buques en la BD */
  public buques: any = [];
  /** Terminales en la BD */
  public terminales: any = [];
  /** Puertos en la BD */
  public puertos: any = [];

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  private permisosUsuario: any;

  /** Referencia al detalle de liquidacion */
  @ViewChild('liquidacionDetalle', { static: true }) liquidacionDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private router: Router, private timeService: TimeService) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.liquidaciones === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.initializeData();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  initializeData() {
    this.cargando = true;
    this.liquidacionesConNominacion = [];

    this.mainService.get('api/buque?activo=true').subscribe((resul) => {
      this.buques = JSON.parse(JSON.stringify(resul));

      this.mainService.get('api/terminal?activo=true').subscribe((resul) => {
        this.terminales = resul;

        this.mainService.get('api/puerto?activo=true').subscribe((resul) => {
          this.puertos = resul;

          this.mainService.get('api/liquidacion?activo=true').subscribe((res) => {
            this.todasLasLiquidaciones = res;
            this.todasLasLiquidaciones.forEach((element) => {
              const buqueAsociado = this.buques.find(({ BUQ_ID }) => BUQ_ID == element.vessel);
              element.nombreBuque = buqueAsociado ? buqueAsociado.BUQ_NOMBRE : '';
              const puertoAsociado = this.puertos.find(({ PUE_ID }) => PUE_ID == element.port);
              element.nombrePuerto = puertoAsociado ? puertoAsociado.PUE_NOMBRE : '';
              const armadorAsociado = this.terminales.find(({ TER_ID }) => TER_ID == element.terminal);
              element.nombreTerminal = armadorAsociado ? armadorAsociado.TER_NOMBRE : '';

              this.liquidacionesConNominacion.push(element);
            });
            this.liquidacionesConNominacion = _.orderBy(this.liquidacionesConNominacion, ['createdAt'], ['desc']);
            this.cargando = false;
          });
        });
      });
    });
  }

  /**
   * Reacciona al evento de creacion de un detalle
   */
  onLiquidacionDetalle() {
    this.openLiquidacionDetalleDialog(this.liquidacionDetalle);
  }

  /**
   * Abre el dialogo del detalle de liquidacion
   * @param dialog Dialogo de la liquidacion
   */
  openLiquidacionDetalleDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones
   * @param event Evento con la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.onDelete(event);
        break;
      case 'viewAndEdit':
        this.viewAndEdit(event);
        break;
    }
  }

  /**
   * Elimina una liquidacion de la BD
   * @param event Evento con los datos de la liquidacion a eliminar
   */
  onDelete(event) {
    if (this.permisosUsuario.liquidaciones !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar la liquidación?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete('api/liquidacion/' + event.data.nv).subscribe(
            (res) => {
              if (res.message) {
                Swal.fire({
                  title: '¡Error!',
                  text: res.message,
                  type: 'error',
                });
              } else {
                Swal.fire('Éxito', 'Liquidación eliminada con éxito', 'success');
                this.liquidacionesConNominacion = [];
                this.initializeData();
              }
            },
            (err) => {
              console.error(err);
              Swal.fire('¡Error!', err.error.message, 'error');
            }
          );
        }
      });
    }
  }

  /**
   * Inicializa la creacion o visualizacion de una liquidacion
   * @param event Evento con los datos de la liquidacion que se quiere editar o visualizar
   */
  async viewAndEdit(event) {
    this.liquidacion = event.data;
    const proforma = await this.mainService
        .get(`api/proforma/${this.liquidacion.idProforma}`)
        .toPromise();
    this.liquidacion.client = proforma.client;
    this.openLiquidacionDetalleDialog(this.liquidacionDetalle);
  }
}
