import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import Chart from 'chart.js';
import { IMyDpOptions } from 'mydatepicker';
import { MainService } from '../services/main.service';
import { IMyDateRangeModel } from 'mydaterangepicker';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public data: any = [];
  public filtroPuertos: any = 'All ports';
  public puertosUnicos: any = [];
  public puertosToSelect: any = [];
  public onInitData = true;

  public amountPuertos: number;
  public puertosData;
  public puertosLabel: any = [];
  public puertosDataSet: any = [];

  public amountNominador: number;
  public nominadorData;
  public nominadorLabel: any = [];
  public nominadorDataSet: any = [];

  public amountStatus: number;
  public statusData;
  public statusLabel: any = [];
  public statusDataSet: any = [];

  public amountCommodity: number;
  public commodityData;
  public commodityLabel: any = [];
  public commodityDataSet: any = [];

  public commodity: any;
  public puertos: any;
  public status: any;
  public nominador: any;

  public chartPuertos: any = [];
  public chartCommodity: any = [];
  public chartStatus: any = [];
  public chartNominador: any = [];

  public puertoCompletos: any = [];
  public statusCompletos: any = [];
  public nominadorCompletos: any = [];
  public empresasCompletas: any = [];
  public commodityCompletos: any = [];

  public filtrado: boolean = false;
  public fechaInicio: any;
  public fechaFinal: any;
  public myDateRangePickerOptions;
  spinerGrafica = true;
  public model: any = {};
  public objetos: any = [];

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy.mm.dd',
  };

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  @ViewChild('graficoPuertos', { static: false }) graficoPuertos: ElementRef<HTMLCanvasElement>;
  @ViewChild('graficoCommodity', { static: false }) graficoCommodity: ElementRef<HTMLCanvasElement>;
  @ViewChild('graficoStatus', { static: false }) graficoStatus: ElementRef<HTMLCanvasElement>;
  @ViewChild('graficoNominador', { static: false }) graficoNominador: ElementRef<HTMLCanvasElement>;

  constructor(private mainService: MainService, private http: HttpClient) {
    this.spinerGrafica = false;
  }

  ngOnInit() {
    this.obtenerPermisos();
    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.puertoCompletos = res;

      this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
        this.empresasCompletas = res;

        this.mainService.get('api/commodity?activo=true').subscribe((res) => {
          this.commodityCompletos = res;

          this.mainService.get('api/nuevo-viaje?activo=true').subscribe((res) => {
            this.data = res;
            this.data.forEach((viaje) => {
              const respuesta = this.puertoCompletos.find((puerto) => puerto.PUE_ID == viaje.port.id);
              viaje.nombrePuerto = respuesta ? respuesta.PUE_NOMBRE : '';
              viaje.nombreNominador = this.empresasCompletas.find(
                (empresa) => empresa.EMP_ID == viaje.disponentOwner.id
              ).EMP_NOMBRE;
              const foundCom = this.commodityCompletos.find((commodity) => {
                if (!viaje.commodity) return false;
                if (viaje.commodity.id == commodity.ID) return true;
                return false;
              });
              viaje.nombreCommodity = foundCom ? foundCom.COMODITY : 'No asignado';
            });

            this.puertosGrafic();
            this.commodityGrafic();
            this.statusGrafic();
            this.nominadorGrafic();
          });
        });
      });
    });
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  public obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
    });
  }

  public puertosGrafic() {
    this.reloadPuertoData();

    let grafico1 = this.graficoPuertos.nativeElement.getContext('2d');
    this.chartPuertos = new Chart(grafico1, {
      type: 'bar',
      data: {
        labels: this.puertosLabel,
        datasets: [this.puertos],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (value.length < 5) return value;
                  return value.substr(0, 4) + '...';
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onResize: (canva, _size) => {
          canva.canvas.style.width = '100%';
          canva.canvas.style.height = '400px';
        },
      },
    });

    if (this.onInitData) {
      this.puertosToSelect = [...this.puertosLabel.sort()];
    }

    this.puertosUnicos = this.puertosLabel.sort();
  }

  public commodityGrafic() {
    this.reloadCommodityData();
    let grafico2 = this.graficoCommodity.nativeElement.getContext('2d');

    this.chartCommodity = new Chart(grafico2, {
      type: 'horizontalBar',
      data: {
        labels: this.commodityLabel,
        datasets: [this.commodity],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onResize: (canva, _size) => {
          canva.canvas.style.width = '100%';
          canva.canvas.style.height = '400px';
        },
      },
    });
  }

  public statusGrafic() {
    this.reloadStatusData();
    let grafico3 = this.graficoStatus.nativeElement.getContext('2d');
    this.chartStatus = new Chart(grafico3, {
      type: 'bar',
      data: {
        labels: this.statusLabel,
        datasets: [this.status],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onResize: (canva, _size) => {
          canva.canvas.style.width = '100%';
          canva.canvas.style.height = '400px';
        },
      },
    });
  }

  public nominadorGrafic() {
    this.reloadNominatorData();
    let grafico4 = this.graficoNominador.nativeElement.getContext('2d');
    this.chartNominador = new Chart(grafico4, {
      type: 'bar',
      data: {
        labels: this.nominadorLabel,
        datasets: [this.nominador],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (value.length < 5) return value;
                  return value.substr(0, 4) + '...';
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onResize: (canva, _size) => {
          canva.canvas.style.width = '100%';
          canva.canvas.style.height = '400px';
        },
      },
    });
  }

  public beginDate;
  public endDate;

  public onDateRangeChanged(event: IMyDateRangeModel) {
    const beginDate = event.beginJsDate;
    const endDate = event.endJsDate;

    if (beginDate && endDate) {
      this.filterDates(beginDate, endDate);
    } else {
    }
  }

  public filterDates(dateStart, dateEnd) {
    this.limpiarDatos();
    this.spinerGrafica = true;
    this.filtrado = true;

    dateStart = moment(dateStart);
    dateEnd = moment(dateEnd).endOf('day');
    dateStart = new Date(dateStart);
    dateEnd = new Date(dateEnd);
    this.fechaInicio = moment(dateStart).format('YYYY-MMM-DD');
    this.fechaFinal = moment(dateEnd).format('YYYY-MMM-DD');
    this.fechasData(dateStart, dateEnd);
  }

  public limpiarDatos() {
    this.amountPuertos = 0;
    this.puertosData = [];
    this.puertosLabel = [];
    this.puertosDataSet = [];
    this.amountNominador = 0;
    this.nominadorData = [];
    this.nominadorLabel = [];
    this.nominadorDataSet = [];
    this.nominadorCompletos = [];
    this.amountStatus = 0;
    this.statusData = [];
    this.statusLabel = [];
    this.statusDataSet = [];
    this.amountCommodity = 0;
    this.commodityData = [];
    this.commodityLabel = [];
    this.commodityDataSet = [];
  }

  public all(data) {
    this.filtrado = false;
    this.limpiarDatos();
    this.puertosData = _.countBy(data, 'port');
    for (var key in this.puertosData) {
      let variables = this.puertosData[key];
      this.puertosLabel.push(key);
      this.puertosDataSet.push(variables);
    }
    this.amountPuertos = this.puertosLabel.length;
    this.puertos = {
      label: 'Puertos',
      data: this.puertosDataSet,
      backgroundColor: 'rgba(0, 99, 132, 0.6)',
    };
    this.chartPuertos.data.labels = this.puertosLabel;
    this.chartPuertos.data.datasets = [this.puertos];
    this.chartPuertos.update();

    this.commodityData = _.countBy(data, 'commodity');
    for (var key in this.commodityData) {
      let variables = this.commodityData[key];
      this.commodityLabel.push(key);
      this.commodityDataSet.push(variables);
    }
    this.amountCommodity = this.commodityLabel.length;
    this.commodity = {
      label: 'Commodity',
      data: this.commodityDataSet,
      backgroundColor: 'rgba(120, 99, 132, 0.6)',
    };
    this.chartCommodity.data.labels = this.commodityLabel;
    this.chartCommodity.data.datasets = [this.commodity];
    this.chartCommodity.update();

    this.statusData = _.countBy(data, 'status');
    for (var key in this.statusData) {
      let variables = this.statusData[key];
      this.statusLabel.push(key);
      this.statusDataSet.push(variables);
    }
    this.amountStatus = this.statusLabel.length;
    this.status = {
      label: 'Estatus',
      data: this.statusDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
    this.chartStatus.data.labels = this.statusLabel;
    this.chartStatus.data.datasets = [this.status];
    this.chartStatus.update();

    this.nominadorData = _.countBy(data, 'nominator');
    let organizador = [];
    for (let top in this.nominadorData) {
      organizador.push([top, this.nominadorData[top]]);
    }
    organizador.sort(function (a, b) {
      return b[1] - a[1];
    });
    for (let index = 0; index < 20; index++) {
      if (organizador[index]) {
        this.nominadorCompletos.push(organizador[index]);
      }
    }
    this.nominadorData = [];
    this.nominadorCompletos.forEach((element) => {
      this.nominadorData[element[0]] = element[1];
    });

    for (var key in this.nominadorData) {
      let variables = this.nominadorData[key];
      this.nominadorLabel.push(key);
      this.nominadorDataSet.push(variables);
    }
    this.amountNominador = this.nominadorLabel.length;
    this.nominador = {
      label: 'Nominador',
      data: this.nominadorDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
    this.chartNominador.data.labels = this.nominadorLabel;
    this.chartNominador.data.datasets = [this.nominador];
    this.chartNominador.update();
  }

  public actualizarGraficos(data) {
    this.puertosData = _.countBy(data, 'port');
    for (var key in this.puertosData) {
      let variables = this.puertosData[key];
      this.puertosLabel.push(key);
      this.puertosDataSet.push(variables);
    }
    this.amountPuertos = this.puertosLabel.length;
    this.puertos = {
      label: 'Puertos',
      data: this.puertosDataSet,
      backgroundColor: 'rgba(0, 99, 132, 0.6)',
    };
    this.chartPuertos.data.labels = this.puertosLabel;
    this.chartPuertos.data.datasets = [this.puertos];
    this.chartPuertos.update();

    this.commodityData = _.countBy(data, 'commodity');
    for (var key in this.commodityData) {
      let variables = this.commodityData[key];
      this.commodityLabel.push(key);
      this.commodityDataSet.push(variables);
    }
    this.amountCommodity = this.commodityLabel.length;
    this.commodity = {
      label: 'Commodity',
      data: this.commodityDataSet,
      backgroundColor: 'rgba(120, 99, 132, 0.6)',
    };
    this.chartCommodity.data.labels = this.commodityLabel;
    this.chartCommodity.data.datasets = [this.commodity];
    this.chartCommodity.update();
    this.statusData = _.countBy(data, 'status');
    for (var key in this.statusData) {
      let variables = this.statusData[key];
      this.statusLabel.push(key);
      this.statusDataSet.push(variables);
    }
    this.amountStatus = this.statusLabel.length;
    this.status = {
      label: 'Estatus',
      data: this.statusDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
    this.chartStatus.data.labels = this.statusLabel;
    this.chartStatus.data.datasets = [this.status];
    this.chartStatus.update();
    this.nominadorData = _.countBy(data, 'nominator');
    for (var key in this.nominadorData) {
      let variables = this.nominadorData[key];
      this.nominadorLabel.push(key);
      this.nominadorDataSet.push(variables);
    }
    this.amountNominador = this.nominadorLabel.length;
    this.nominador = {
      label: 'Nominador',
      data: this.nominadorDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
    this.chartNominador.data.labels = this.nominadorLabel;
    this.chartNominador.data.datasets = [this.nominador];
    this.chartNominador.update();
  }

  public objetificar() {
    this.objetos = [];
    this.objetos.push([
      '#',
      'VIA_ID',
      'NV',
      'AGENT',
      'YEAR',
      'MONTH',
      'STATUS',
      'VESSEL',
      'DWT',
      'VESSEL TYPE',
      'LOA',
      'COUNTRY ORIGIN',
      'PORT ORIGIN',
      'PORT',
      'TERMINAL',
      'PIER',
      'ARRIVE FWD',
      'ARRIVE AFT',
      'ARRIVE DRAFT',
      'ARRIVE BUNKER FO',
      'ARRIVE BUNKER DO',
      'ARRIVE BUNKER FW',
      'SAILING FWD',
      'SAILING AFT',
      'SAILING DRAFT',
      'SAILING BUNKER FO',
      'SAILING BUNKER DO',
      'SAILING BUNKER FW',
      'OPERATION TYPE',
      'DAYS',
      'BUSINESS LINE',
      'COMMODITY',
      'TYPE PRODUCT',
      'RAW MATERIAL',
      'PRODUCT',
      'AMOUNT',
      'MEASURE',
      'PRODUCTIVITY',
      'ACCUMULATED',
      'ROB',
      'COMMENCED OPS',
      'ETA',
      'ETN',
      'ETB',
      'ETD',
      'ETS',
      'ETC',
      'ATA',
      'ATN',
      'ATB',
      'ATD',
      'ATS',
      'ATC',
      'MV1',
      'MV2',
      'SHIP OWNER',
      'NOMINATOR',
      'CHARTERER',
      'SHIPPER',
      'CONSIGNEE',
      'NOTIFY',
      'KIM',
      'BOARDING AGENT',
      'OPERATION TYPE',
    ]);

    const viajesRecaladas = [];
    this.data.forEach((viaje) => {
      viaje.recaladas.forEach((viajeRecalada) => {
        viajesRecaladas.push({ ...viaje, nv: `${viaje.nv} (${viajeRecalada.index})`, recalada: viajeRecalada });
      });
    });

    for (let i = 0; i < viajesRecaladas.length; i++) {
      let y = viajesRecaladas[i];
      let x: any;
      x = [
        i + 1 + '',
        y._viaId || 'N/A',
        y.nv || 'N/A',
        y.agent || 'N/A',
        y.year || 'N/A',
        y.month || 'N/A',
        y.status || 'N/A',
        y.vessel.nombre || 'N/A',
        y.dwt || 'N/A',
        y.vesselType || 'N/A',
        y.recalada.informacionExtra.load || 'N/A',
        y.countryOrigin || 'N/A',
        y.portOrigin || 'N/A',
        y.port.origin || 'N/A',
        y.recalada.terminal.nombre || 'N/A',
        y.pier || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveAft) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveFwd) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveDraft) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveBunkerFo) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveBunkerDo) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.arriveBunkerFw) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingFwd) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingAft) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingDraft) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingBunkerFo) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingBunkerDo) || 'N/A',
        (y.recalada.adConditions && y.recalada.adConditions.sailingBunkerFw) || 'N/A',
        y.operationType || 'N/A',
        y.days || 'N/A',
        (y.businessLine && y.businessLine.nombre) || 'N/A',
        (y.commodity && y.commodity.nombre) || 'N/A',
        y.typeProduct || 'N/A',
        y.rawMaterial || 'N/A',
        y.product || 'N/A',
        y.amount || 'N/A',
        y.measure || 'N/A',
        y.productivity || 'N/A',
        y.accumulated || 'N/A',
        y.rob || 'N/A',
        y.commencedOps || 'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.eta &&
          moment.utc(y.recalada.prospectos.eta).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.etn &&
          moment.utc(y.recalada.prospectos.etn).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.etb &&
          moment.utc(y.recalada.prospectos.etb).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.etd &&
          moment.utc(y.recalada.prospectos.etd).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.ets &&
          moment.utc(y.recalada.prospectos.ets).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.etc &&
          moment.utc(y.recalada.prospectos.etc).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.ata &&
          moment.utc(y.recalada.prospectos.ata).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.atn &&
          moment.utc(y.recalada.prospectos.atn).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.atb &&
          moment.utc(y.recalada.prospectos.atb).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.atd &&
          moment.utc(y.recalada.prospectos.atd).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.ats &&
          moment.utc(y.recalada.prospectos.ats).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        (y.recalada.prospectos &&
          y.recalada.prospectos.atc &&
          moment.utc(y.recalada.prospectos.atc).format('DD-MM-YYYY HH:mm')) ||
          'N/A',
        y.mv1 || 'N/A',
        y.mv2 || 'N/A',
        y.shipOwner || 'N/A',
        y.nombreNominador || 'N/A',
        (y.charterer && y.charterer.nombre) || 'N/A',
        y.shipper || 'N/A',
        y.consignee || 'N/A',
        y.notify || 'N/A',
        y.kim.nombre || 'N/A',
        (y.boardingAgent && y.boardingAgent.nombre + ' ' + y.boardingAgent.apellido) || 'N/A',
        (y.recalada.operationType && y.recalada.operationType.nombre) || 'N/A',
      ];

      this.objetos.push(x);
    }
    this.export();
  }

  public export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.objetos);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    if (this.filtrado) {
      XLSX.writeFile(wb, 'Viajes desde ' + this.fechaInicio + ' a ' + this.fechaFinal + '.xlsx');
    } else {
      XLSX.writeFile(wb, 'Viajes.xlsx');
    }
  }

  public fechasData(dateStart, dateEnd) {
    this.data = [];

    this.beginDate = dateStart;
    this.endDate = dateEnd;
    this.cargarData();

    // this.mainService.get('api/nuevo-viaje?activo=true').subscribe((res) => {
    //   const allVoyages = [];

    //   res.forEach((voyage) => {
    //     voyage.recaladas.forEach((recalada) => {
    //       voyage.recalada = recalada;
    //       allVoyages.push(voyage);
    //     });
    //   });

    // this.data = allVoyages.filter((voyage) => {
    //   if (!voyage.recalada.prospectos) return false;
    //   if (!voyage.recalada.prospectos.eta) return false;
    //   const activityDate = new Date(voyage.recalada.prospectos.eta);
    //   const x = moment(activityDate, 'YYYY-MM-DD').toDate();
    //   return x >= dateStart && x <= dateEnd;
    // });

    //   if (this.filtroPuertos !== 'All ports') {
    //     this.data = this.data.filter((viaje) => viaje.port == this.filtroPuertos);
    //   }

    //   console.log(this.data.length);

    //   this.actualizarGraficos(this.data);
    //   this.spinerGrafica = false;

    //   this.limpiarDatos();
    //   this.puertosGrafic();
    //   this.commodityGrafic();
    //   this.statusGrafic();
    //   this.nominadorGrafic();
    // });
  }

  public cargarData() {
    this.spinerGrafica = true;
    this.data = [];

    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.puertoCompletos = res;

      this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
        this.empresasCompletas = res;

        this.mainService.get('api/commodity?activo=true').subscribe((res) => {
          this.commodityCompletos = res;

          this.mainService.get('api/nuevo-viaje?activo=true').subscribe((res) => {
            this.data = res;

            const allVoyages = [];

            res.forEach((voyage) => {
              voyage.recaladas.forEach((recalada) => {
                voyage.recalada = recalada;
                allVoyages.push(voyage);
              });
            });

            this.data = allVoyages;

            if (this.filtroPuertos != 'All ports') {
              this.data = res.filter((viaje) => viaje.port.nombre === this.filtroPuertos);
            }

            this.data.forEach((viaje) => {
              const respuesta = this.puertoCompletos.find((puerto) => puerto.PUE_ID == viaje.port.id);
              viaje.nombrePuerto = respuesta ? respuesta.PUE_NOMBRE : '';
              viaje.nombreNominador = this.empresasCompletas.find(
                (empresa) => empresa.EMP_ID == viaje.disponentOwner.id
              ).EMP_NOMBRE;

              const foundCom = this.commodityCompletos.find((commodity) => {
                if (!viaje.commodity) return false;
                if (viaje.commodity.id == commodity.ID) return true;
                return false;
              });

              viaje.nombreCommodity = foundCom ? foundCom.COMODITY : 'No asignado';
            });

            if (this.beginDate && this.endDate) {
              this.data = allVoyages.filter((voyage) => {
                if (!voyage.recalada.prospectos) return false;
                if (!voyage.recalada.prospectos.eta) return false;
                const activityDate = new Date(voyage.recalada.prospectos.eta);
                const x = moment(activityDate, 'YYYY-MM-DD').toDate();
                return x >= this.beginDate && x <= this.endDate;
              });
            }

            this.onInitData = false;

            // this.all(this.data);
            this.limpiarDatos();
            this.reloadPuertosGrafic();
            this.reloadCommodityGrafic();
            this.reloadStatusGrafic();
            this.reloadNominatorGrafic();
            this.spinerGrafica = false;
          });
        });
      });
    });

    // if (this.filtroPuertos == 'All ports') {
    //   this.mainService.get('api/barco').subscribe((res) => {
    //     this.data = res;
    //     this.all(this.data);
    //     this.spinerGrafica = false;
    //   });
    // } else {
    //   this.mainService.get('api/barco').subscribe((res) => {
    //     this.data = res.filter((viaje) => viaje.port._id == this.filtroPuertos);
    //     this.all(this.data);
    //     this.spinerGrafica = false;
    //   });
    // }
  }

  reloadPuertosGrafic() {
    this.reloadPuertoData();
    this.chartPuertos.data.labels = this.puertosLabel;
    this.chartPuertos.data.datasets = [this.puertos];
    this.chartPuertos.update();
  }

  reloadCommodityGrafic() {
    this.reloadCommodityData();
    this.chartCommodity.data.labels = this.commodityLabel;
    this.chartCommodity.data.datasets = [this.commodity];
    this.chartCommodity.update();
  }

  reloadStatusGrafic() {
    this.reloadStatusData();
    this.chartStatus.data.labels = this.statusLabel;
    this.chartStatus.data.datasets = [this.status];
    this.chartStatus.update();
  }

  reloadNominatorGrafic() {
    this.reloadNominatorData();
    this.chartNominador.data.labels = this.nominadorLabel;
    this.chartNominador.data.datasets = [this.nominador];
    this.chartNominador.update();
  }

  reloadPuertoData() {
    this.puertosData = _.countBy(this.data, 'nombrePuerto');
    for (var key in this.puertosData) {
      let variables = this.puertosData[key];
      this.puertosLabel.push(key);
      this.puertosDataSet.push(variables);
    }
    this.amountPuertos = this.puertosLabel.length;
    this.puertos = {
      label: 'Puertos',
      data: this.puertosDataSet,
      backgroundColor: 'rgba(0, 99, 132, 0.6)',
    };
  }

  reloadCommodityData() {
    this.commodityData = _.countBy(this.data, 'nombreCommodity');
    for (var key in this.commodityData) {
      let variables = this.commodityData[key];
      this.commodityLabel.push(key);
      this.commodityDataSet.push(variables);
    }
    this.amountCommodity = this.commodityLabel.length;
    this.commodity = {
      label: 'Commodity',
      data: this.commodityDataSet,
      backgroundColor: 'rgba(120, 99, 132, 0.6)',
    };
  }

  reloadStatusData() {
    this.statusData = _.countBy(this.data, 'status');
    for (var key in this.statusData) {
      let variables = this.statusData[key];
      this.statusLabel.push(key);
      this.statusDataSet.push(variables);
    }
    this.amountStatus = this.statusLabel.length;
    this.status = {
      label: 'Estatus',
      data: this.statusDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
  }

  reloadNominatorData() {
    this.nominadorData = _.countBy(this.data, 'nombreNominador');
    let organizador = [];
    for (let top in this.nominadorData) {
      organizador.push([top, this.nominadorData[top]]);
    }
    organizador.sort(function (a, b) {
      return b[1] - a[1];
    });
    for (let index = 0; index < 20; index++) {
      if (organizador[index]) {
        this.nominadorCompletos.push(organizador[index]);
      }
    }
    this.nominadorData = [];
    this.nominadorCompletos.forEach((element) => {
      this.nominadorData[element[0]] = element[1];
    });
    for (var key in this.nominadorData) {
      let variables = this.nominadorData[key];
      this.nominadorLabel.push(key);
      this.nominadorDataSet.push(variables);
    }
    this.amountNominador = this.nominadorLabel.length;
    this.nominador = {
      label: 'Nominador',
      data: this.nominadorDataSet,
      backgroundColor: 'rgba(240, 99, 132, 1)',
    };
  }
}
