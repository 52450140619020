import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { multiSelectsIns } from '../../instructions/multipleSelect.ins';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-empresa-sql',
  templateUrl: './empresa-sql.component.html',
  styleUrls: ['./empresa-sql.component.scss'],
})
export class EmpresaSqlComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Empresa actualmente seleccionada */
  empresa: any = {};
  /** Empresa congelada para cuando cierre sin guardar */
  empresaCongelada: any = {};
  /** Posibles tipos de empresas */
  tiposEmpresa: string[] = ['PROVEEDOR', 'CLIENTE'];
  public dataEmpresas: any = [];
  public empresaExistente: any = {};
  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      EMP_ID: {
        title: 'ID',
      },
      EMP_NOMBRE: {
        title: 'Nombre',
      },
      EMP_CODIGO_SAP: {
        title: 'Código SAP',
      },
      EMP_CIUDAD: {
        title: 'Ciudad',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any = [];
  /** Indica si los datos fueron enviados */
  enviado: boolean = false;
  /** Indica que algo se esta cargando en el modulo */
  public cargando: boolean = true;

  /** Usuario actual */
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  public instructions = {
    companyType: multiSelectsIns.companyType,
  };

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  /**
   * Inicializa al usuario actual y carga la pagina
   */
  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.empresas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las acciones
   * @param event Evento con los datos de la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
    }
  }

  /**
   * Inicializa la edicion de una empresa
   * @param dialog Referencia al dialogo del detalle de la empresa
   * @param event Evento con los datos de la empresa a editar
   */
  onEditar(dialog, event) {
    this.instructions.companyType.defaults.value = event.data.tipo;

    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.empresa = {};
      this.empresa.EMP_ID = '';
      this.empresa.tipo = '';
      this.empresaCongelada = event.data;
      this.empresa = Object.assign({}, { ...event.data });
      this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }
  }

  closeDialog(ref: any) {
    this.empresa = this.empresaCongelada;
    this.empresa = {};
    ref.close();
  }

  /**
   * Abre el detalle en modod editar
   */
  openDialogEdit(edit) {
    this.enviado = false;
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de una empresa
   */
  crearEmpresa() {
    this.empresa = {};
    this.empresa.EMP_ID = '';
    this.empresa.tipo = '';
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo en modo creacion
   */
  openDialogSave(dialog) {
    this.enviado = false;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Crea una empresa en la BD
   */
  public crear() {
    this.enviado = true;
    this.cargando = true;
    if (this.empresa.EMP_NOMBRE && this.empresa.tipo.length > 0) {
      this.mainService.get('api/global_variable/EMP_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.empresa.EMP_ID = 2300; //Primer valor del ID
          const empresaIdGlobalVariable = {
            key: 'EMP_ID',
            value: this.empresa.EMP_ID,
          };
          this.mainService.post('api/global_variable', empresaIdGlobalVariable).subscribe((result) => {});
        } else {
          this.empresa.EMP_ID = res.value;
        }

        this.mainService.put('api/global_variable/EMP_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/empresa_sql', this.empresa).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó la empresa exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar la empresa, verifique la información', 'warning');
          }
          this.cargando = false;
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la empresa, verifique la información', 'warning');

      this.cargando = false;
    }
  }

  /**
   * Redirigue a la pagina de visualizacion de una empresa
   * @param event Evento con los datos de la empresa a ver
   */
  onVer(event) {
    this.router.navigate(['home/empresa-sql-ver/' + event.data._id]);
  }

  /**
   * Actualiza una empresa
   */
  onActualizar() {
    this.enviado = true;
    delete this.empresa.createdAt;
    if (this.empresa.EMP_NOMBRE && this.empresa.tipo.length > 0) {
      this.cargando = true;

      let empresaActualizar = this.empresa;
      let data = empresaActualizar;
      this.mainService.put('api/empresa_sql/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la empresa exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.empresa = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la empresa, verifique la información', 'warning');
        }

        this.cargando = false;
        this.enviado = false;
      });
    } else {
      // this.enviado = true;

      Swal.fire('Error', 'No se pudo actualizar la empresa, verifique la información', 'warning');
      this.cargando = false;
    }
  }

  /**
   * Carga los datos de la BD
   */
  onCargar() {
    this.cargando = true;

    this.data = [];
    this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
      this.data = res;

      this.data.forEach((item) => {
        item.EMP_ID = parseInt(item.EMP_ID);
      });

      this.data = _.orderBy(this.data, ['EMP_ID', 'desc']);

      this.dataEmpresas = JSON.parse(JSON.stringify(this.data));
      this.cargando = false;
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el empresa?</strong>',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          let empresaEliminar = event.data;
          this.cargando = true;
          this.mainService
            .delete(`api/empresa_sql/${empresaEliminar._id}?empresaId=${event.data.EMP_ID}`)
            .subscribe((res) => {
              if (res) {
                if (res.message) {
                  Swal.fire({
                    title: 'Error',
                    text: res.message,
                    type: 'error',
                  });
                  return;
                }
                Swal.fire('Éxito', 'empresa eliminada con éxito', 'success');
                this.onCargar();
              }
            });
        }
        this.cargando = false;
      });
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          EMP_ID: this.datos[i][0],
          EMP_NOMBRE: this.datos[i][1],
          EMP_CIUDAD: this.datos[i][2],
          EMP_COMENTARIOS: this.datos[i][2],
          EMP_DIRECCION: this.datos[i][3],
          EMP_LOCACION: this.datos[i][4],
          EMP_PAIS: this.datos[i][2],
          EMP_TELEFONO: this.datos[i][2],
          EMP_NIT: this.datos[i][2],
        };
        this.empresaExistente = {};
        this.empresaExistente = this.getEmpresaByID(parseInt(excel.EMP_ID));
        if (excel.EMP_ID > 1) {
          if (this.empresaExistente == null) {
            this.mainService.post('api/empresa_sql', excel).subscribe(
              (res) => {},
              (err) => {}
            );
          } else {
          }
        }
      }
    };
    reader.readAsBinaryString(target.files[0]);
    this.onCargar();
  }

  public getEmpresaByID(evt) {
    let x = _.find(this.dataEmpresas, { EMP_ID: evt });
    if (x) return x;
    else return null;
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
