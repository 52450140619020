import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { S3Service } from '../services/s3.service';
import { S3 } from 'aws-sdk';
import { BuqueModel } from '../interfaces/requests.interface';

type AOA = any[][];
@Component({
  selector: 'app-buques',
  templateUrl: './buques.component.html',
  styleUrls: ['./buques.component.scss'],
})
export class BuquesComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  /** Para el icono y foto que se están adjuntando */
  @ViewChild('iconoAAdjuntar', { static: true }) iconoAAdjuntar: ElementRef;
  @ViewChild('fotoAAdjuntar', { static: true }) fotoAAdjuntar: ElementRef;

  /** Datos de la BD de los buques */
  public dataBuques: any = [];
  /** Buque seleccionado*/
  public buque: any = {};
  /** Buque Existente */
  public buqueExistente: any = {};
  /** Switch condition para saber si se está ejecutando una carga, y activar spinner */
  public archivoAdjuntoCargandose: boolean[] = [false, false];

  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      BUQ_ID: {
        title: 'ID Buque',
      },
      BUQ_NOMBRE: {
        title: 'Nombre',
      },
      BUQ_IMO: {
        title: 'IMO',
      },
      BUQ_ESLORA: {
        title: 'LOA',
      },
      BUQ_CORREO: {
        title: 'Correo',
      },
      BUQ_BANDERA: {
        title: 'Bandera',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /** Indica si los datos fueron enviados */
  enviado: boolean = false;
  datos: any = [];

  /** Usuario actual */
  public usuario: any;
  /** Datos del buque actual */
  public data: any;

  /** Bucket de s3 */
  private bucket: S3;
  /** url del documento cargado */
  private documentURL: string;

  public source: LocalDataSource;
  objetos: any = [];
  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private s3Service: S3Service,
    private timeService: TimeService
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  /**
   * Inicializa los datos del usuario actual
   */
  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
  }

  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.buques === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las acciones
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Maneja la accion de editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.buques !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.buque = {};
      this.buque = JSON.parse(JSON.stringify(event.data));
      this.buque._id = event.data._id;
      this.dialogService
        .open(dialog, { context: 'this is some additional data passed to dialog' })
        .onClose.subscribe(() => {
          this.buque = {};
        });
    }
  }

  /* onAdjuntarArchivo(campo) {
    const MAX_WIDTH = 280;
    const MAX_HEIGHT = 280;
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let { width, height } = img;

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          // Calcular el factor de reducción
          const widthRatio = MAX_WIDTH / width;
          const heightRatio = MAX_HEIGHT / height;
          const resizeRatio = Math.min(widthRatio, heightRatio);

          // Ajustar las dimensiones manteniendo la relación de aspecto
          width = width * resizeRatio;
          height = height * resizeRatio;
          // Aquí podrías redimensionar la imagen o advertir al usuario.
        }

        // Limpia el objeto URL para evitar pérdida de memoria
        URL.revokeObjectURL(img.src);
      };
    }
    /* let archivoAAdjuntar;
    if (campo === 'icono') {
      archivoAAdjuntar = this.iconoAAdjuntar.nativeElement;
    } else {
      archivoAAdjuntar = this.fotoAAdjuntar.nativeElement;
    }
    archivoAAdjuntar.click(); 
  } */

  onEliminarFoto() {
    this.buque.BUQ_FOTO_REAL = null;
  }

  onEliminarIcono() {
    this.buque.BUQ_FOTO_ICONO = null;
  }

  onAdjuntarArchivo(campo: string): void {
    let archivoAAdjuntar;
    if (campo === 'icono') {
      archivoAAdjuntar = this.iconoAAdjuntar.nativeElement;
    } else {
      archivoAAdjuntar = this.fotoAAdjuntar.nativeElement;
    }
    archivoAAdjuntar.click();
  }

  onSeleccionarArchivoAAdjuntar(event: Event, campo: string): void {
    const selectedFile = (event.target as HTMLInputElement).files[0];

    // Validación de tipo de archivo
    const allowedTypes = ['image/jpeg', 'image/png'];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (!allowedTypes.includes(selectedFile.type) || !['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      Swal.fire('Error', 'Seleccione un archivo de tipo imagen', 'error');
      return;
    }

    if (campo === 'icono') {
      this.archivoAdjuntoCargandose[0] = true;
    } else {
      this.archivoAdjuntoCargandose[1] = true;
    }

    // Cargar y redimensionar la imagen si es necesario
    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);

    img.onload = () => {
      let { width, height } = img;

      const MAX_WIDTH = 280;
      const MAX_HEIGHT = 280;

      if (width > MAX_WIDTH || height > MAX_HEIGHT) {
        // Calcular factor de reducción
        const widthRatio = MAX_WIDTH / width;
        const heightRatio = MAX_HEIGHT / height;
        const resizeRatio = Math.min(widthRatio, heightRatio);

        width = width * resizeRatio;
        height = height * resizeRatio;

        // Crear canvas para redimensionar
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Convertir a Blob y proceder con la carga
        canvas.toBlob((blob) => {
          if (blob) {
            const archivoRedimensionado = new File([blob], selectedFile.name, { type: selectedFile.type });
            this.subirArchivo(archivoRedimensionado, campo);
          }
          URL.revokeObjectURL(img.src);
        }, selectedFile.type);
      } else {
        // Si no es necesario redimensionar
        this.subirArchivo(selectedFile, campo);
        URL.revokeObjectURL(img.src);
      }
    };
  }

  private subirArchivo(archivo: File, campo: string): void {
    const { params, options } = this.s3Service.getInfoForUpload('archivosBuque', archivo);
    this.bucket.upload(params, options, (err, data) => {
      if (err) {
        this.s3Service.showErrorUploading();
      } else {
        this.documentURL = data.Location.toString();
        if (campo === 'icono') {
          this.buque.BUQ_FOTO_ICONO = this.documentURL;
          this.archivoAdjuntoCargandose[0] = false;
        } else {
          this.buque.BUQ_FOTO_REAL = this.documentURL;
          this.archivoAdjuntoCargandose[1] = false;
        }
      }
    });
  }

  /* onSeleccionarArchivoAAdjuntar(event, campo) {
    const selectedFile = event.target.files[0];

    const allowedTypes = ['image/jpeg', 'image/png'];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (
      !allowedTypes.includes(selectedFile.type) ||
      (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png')
    ) {
      Swal.fire('Error', 'Seleccione un archivo de tipo imagen', 'error');
      return;
    }

    if (campo === 'icono') {
      this.archivoAdjuntoCargandose[0] = true;
    } else {
      this.archivoAdjuntoCargandose[1] = true;
    }
    const archivoSeleccionado = event.target.files[0];
    if (archivoSeleccionado !== undefined) {
      // Obtener la información necesaria
      const { params, options } = this.s3Service.getInfoForUpload('archivosBuque', archivoSeleccionado);
      this.bucket.upload(params, options, (err, data) => {
        if (err) {
          this.s3Service.showErrorUploading();
        } else {
          this.documentURL = data.Location.toString();
          if (campo === 'icono') {
            this.buque.BUQ_FOTO_ICONO = this.documentURL;
            this.archivoAdjuntoCargandose[0] = false;
          } else {
            this.buque.BUQ_FOTO_REAL = this.documentURL;
            this.archivoAdjuntoCargandose[1] = false;
          }
        }
      });
    }
  } */

  /**
   * Arbe el dialogo con el detalle
   * @param edit Datos del buquq a editar
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de un buque
   */
  crearBuque() {
    if (this.permisosUsuario.buques !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.openDialogSave(this.dialog);
    }
  }

  /**
   * Abre el detalle del buque a guardar
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja la carga de la pagina
   */
  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (
      this.buque.BUQ_NOMBRE &&
      this.buque.BUQ_ESLORA &&
      this.buque.BUQ_DWT &&
      this.buque.BUQ_GT &&
      this.buque.BUQ_MANGA &&
      this.buque.BUQ_ANO_CONSTRUCCION &&
      this.buque.BUQ_BANDERA &&
      this.buque.BUQ_ESPECIALIDAD &&
      this.buque.BUQ_IMO &&
      this.buque.BUQ_LETRAS_LLAMADA
    ) {
      const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.buque.BUQ_CORREO && !emailPattern.test(this.buque.BUQ_CORREO)) {
        Swal.fire(
          'Error',
          `No se pudo agregar el buque, verifique el formato del correo electrónico ingresado`,
          'error'
        );
        return;
      }
      this.mainService.get('api/global_variable/BUQ_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.buque.BUQ_ID = 15565; //Primer valor del ID
          const buqueIdGlobalVariable = {
            key: 'BUQ_ID',
            value: this.buque.BUQ_ID,
          };
          this.mainService.post('api/global_variable', buqueIdGlobalVariable).subscribe((result) => {});
        } else {
          this.buque.BUQ_ID = res.value;
        }

        this.mainService.put('api/global_variable/BUQ_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/buque', this.buque).subscribe((result) => {
          if (result && !result.errors) {
            Swal.fire('Éxito', 'Se agregó el buque exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.buque = {};
            this.onCargar();
          } else {
            Swal.fire(
              'Error',
              'No se pudo agregar el buque, verifique la información'.concat(
                result._message ? '. ' + result._message : ''
              ),
              'error'
            );
          }
        });
      });
    } else {
      let missingFields = [];
      if (!this.buque.BUQ_NOMBRE) {
        missingFields.push('Nombre');
      }
      if (!this.buque.BUQ_ESLORA) {
        missingFields.push('LOA');
      }
      if (!this.buque.BUQ_DWT) {
        missingFields.push('DWT');
      }
      if (!this.buque.BUQ_GT) {
        missingFields.push('GWT');
      }
      if (!this.buque.BUQ_MANGA) {
        missingFields.push('Manga');
      }
      if (!this.buque.BUQ_ANO_CONSTRUCCION) {
        missingFields.push('Año de construcción');
      }
      if (!this.buque.BUQ_BANDERA) {
        missingFields.push('Bandera');
      }
      if (!this.buque.BUQ_ESPECIALIDAD) {
        missingFields.push('Especialidad');
      }
      if (!this.buque.BUQ_IMO) {
        missingFields.push('IMO');
      }
      if (!this.buque.BUQ_LETRAS_LLAMADA) {
        missingFields.push('Letras llamado');
      }

      Swal.fire(
        'Error',
        `No se pudo agregar el buque, verifique la información obligatoria : ${missingFields.join(', ')}`,
        'error'
      );
    }
  }

  onVer(event) {
    this.router.navigate(['home/buque-ver/' + event.data._id]);
  }

  onActualizar() {
    if (
      this.buque.BUQ_NOMBRE &&
      this.buque.BUQ_ESLORA &&
      this.buque.BUQ_DWT &&
      this.buque.BUQ_GT &&
      this.buque.BUQ_MANGA &&
      this.buque.BUQ_ANO_CONSTRUCCION &&
      this.buque.BUQ_BANDERA &&
      this.buque.BUQ_ESPECIALIDAD &&
      this.buque.BUQ_IMO &&
      this.buque.BUQ_LETRAS_LLAMADA
    ) {
      let data = this.buque;
      this.mainService.put('api/buque/' + data._id, data).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se actualizó el buque exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo actualizar el buque, verifique la información', 'warning');
          }
        },
        (error) => {
          console.error(error);
          Swal.fire('Error', error.error.message, 'error');
        }
      );
    } else {
      Swal.fire('Error', 'No se pudo actualizar el buque, verifique la información', 'warning');
    }
  }

  onCargar() {
    this.data = [];
    this.mainService.get('api/buque?activo=true').subscribe((res) => {
      this.dataBuques = JSON.parse(JSON.stringify(res));
      this.data = res;
      this.data.forEach((element) => {
        element.BUQ_ID = Number.parseFloat(element.BUQ_ID);
      });
      this.data = _.orderBy(this.data, ['BUQ_ID'], ['asc']);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.buques !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el buque?</strong>',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          let buqueEliminar = event.data;
          this.spinerGuardar = true;
          this.mainService.delete('api/buque/' + buqueEliminar.BUQ_ID).subscribe(
            (res: Partial<BuqueModel>) => {
              Swal.fire('Éxito', 'Buque eliminado con éxito', 'success');
              this.spinerGuardar = false;
              this.onCargar();
            },
            (err) => {
              console.error(err);
              Swal.fire('Error', err.error.message, 'error');
            }
          );
        }
      });
    }
  }

  public getBuqueByID(evt) {
    let x = _.find(this.dataBuques, { BUQ_ID: evt });
    if (x) return x;
    else return null;
  }

  closeModalCreate() {
    this.buque = {};
  }
}
